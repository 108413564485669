@charset "utf-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&family=Noto+Sans:wght@400;700&display=swap");

//Configuration and helpers
@import 'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

//Base stuff
@import 'base/base',
'base/typography',
'base/helpers';

//customer
@import 'layout/flex',
'layout/module';

/* ie */
.ie {width:100%;height:50px;background-color:#f8f8f8;display:flex;align-items:center;position:fixed;left:0;top:0;z-index:99999;display:none;
  .ico-warning {width:24px;height:24px;background:url('#{$IMG_PATH}/common/ico-warning.png') no-repeat;background-size:24px auto;margin-right:10px;display:inline-block;vertical-align:middle;}
  .txt {font-size:15px;line-height:24px;}
  a {background-color:#0067b8;border-radius:20px;margin:0 5px;padding:3px 15px;color:#fff;font-weight:bold;letter-spacing:0;}
}
body.connect-ie {
  .ie {display:flex;}
  .gnb {top:50px;}
}

/* common */
.container {width:100%;padding:0 15px;}
.highlight {color:#7f76fd;}
.br {display:block;}
.br2 {display:inline-block;}
.swiper-slide {
  display:-webkit-box;
  display:-ms-flexbox;
  display:-webkit-flex;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  -webkit-justify-content:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  -webkit-align-items:center;
  align-items:center;
}
.btn-top {width:60px;height:60px;background:url('#{$IMG_PATH}/common/btn-top.png') no-repeat;background-size:60px auto;margin:0 10px;position:fixed;right:5%;bottom:5%;z-index:99;display:none;
  &.active {display:block;}
}
.float-download {margin-top:-110px;position:fixed;right:5%;top:50%;z-index:999;display:none;
  ul li {padding:10px 0;position:relative;z-index:9;
    button, .won {width:80px;height:80px;background-color:#000;border-radius:80px;margin-bottom:10px;display:inline-block;overflow:hidden;}
    .btn-float {width:80px;height:80px;background:url('#{$IMG_PATH}/common/btn-float.png') no-repeat;background-size:160px auto;display:inline-block;
      &.googleplay {background-position:0 0;}
      &.appstore {background-position:0 -80px;}
      &.ico-float {background-position:0 -160px;}
    }
    ul {display:flex;align-items:center;position:absolute;right:80px;top:0;z-index:99;
      li {margin-right:30px;text-align:center;display:none;
        .txt {color:#fff;
          strong {font-size:16px;display:block;}
        }
      }
    }
    &.active {
      button {background-color:#fff;}
      .btn-float {
        &.googleplay {background-position:-80px 0;}
        &.appstore {background-position:-80px -80px;}
      }
      ul li {display:block;}
    }
  }
}

/* gnb */
.logo {width:140px;height:auto;}
.gnb {
  height:60px;
  background-color:#fff;
  position:fixed;
  top:0;
  right:0;
  left:0;
  z-index:9999;
  .gnb-wrap {
    @extend .row;
    height:60px;
    padding-left:15px;
  }
  .gnb-content {
    align-items:center;
    justify-content:space-between;
    display:flex;
    position:relative;
    .btn-store {
      width:115px;
      height:31px;
      background:url('#{$IMG_PATH}/common/btn-store.png') no-repeat;
      background-size:115px auto;
      display:inline-block;
      &.googleplay {
        background-position:0 0;
      }
      &.appstore {
        background-position:0 -31px;
      }
    }
  }
  .gnb-content-left {
    @extend .float-start;
    padding-top:10px;
    .menu {margin-top:-11px;position:absolute;right:15px;top:50%;display:none;
      ul {
        li {padding:0 20px;display:inline-block;position:relative;
          a {font-size:15px;font-weight:bold;}
          .btn-menu {width:auto;height:auto;background:none;margin:0;font-size:15px;font-weight:bold;float:none;}
          .download {width:330px;height:60px;background-color:#fff;border-radius:60px;border-radius:60px;margin-left:-165px;text-align:center;box-shadow:0 3px 10px 0 rgba(0, 0, 0, 0.2);position:absolute;left:50%;top:55px;display:none;
            li {padding:12px 15px;position:relative;
              &:first-child:after {content:'';width:1px;height:20px;background-color:#ccc;margin-top:-10px;position:absolute;right:0;top:50%;}
            }
            &.active {display:block;}
          }
          .lang {position:relative;
            > a {padding-right:20px;position:relative;z-index:99;
              &:after {content:'';width:0;height:0;margin-top:-3px;border-left:6px solid transparent;border-right:6px solid transparent;border-top:6px solid #000;position:absolute;right:0;top:50%;z-index:99;}
              &.active {color:#000;}
              &.active:after {border-bottom:6px solid #000;border-top:0;}
            }
            ul {width:100px;background-color:#fff;border:1px solid #ccc;border-radius:15px;padding:12px 19px;position:absolute;left:-20px;top:-20px;z-index:9;display:none;
              li {padding:5px 0;
                &:first-child {padding-top:35px;}
              }
            }
          }
        }
      }
    }
  }
  .gnb-content-right {
    @extend .float-end;
    @extend .d-block;
    .sidecanvas-open {
      width:60px;
      height:60px;
      background:url('#{$IMG_PATH}/common/btn-hamburger.png') no-repeat;
      background-size:60px auto;
      display:inline-block;
      position:absolute;
      right:0;
      top:0;
    }
  }
}

/* sidecanvas */
.sidecanvas {
  width:375px !important;
  max-width:100%;
  height:100vh;
  background-color:#fff;
  position:fixed;
  bottom:0;
  top:0;
  right:0;
  z-index:1045;
  display:flex;
  flex-direction:column;
  visibility:hidden;
  transition:transform .3s ease-in-out;
  transform:translateX(100%);
  .sidecanvas-header {
    margin-bottom:30px;
    padding-left:20px;
    .sidecanvas-header-title {
      padding-top:23px;
      float:left;
    }
    .sidecanvas-close {
      float:right;
      img {
        width:60px;
        height:auto;
      }
    }
  }
  .sidecanvas-body {
    padding:0 15px;
    overflow-y:auto;
    z-index:9999;
    flex-grow:1;
    .download {
      @extend .row;
      @extend .justify-content-around;
      margin-bottom:20px;
      .top {
        margin-bottom:20px;
        position:relative;
        span {
          background-color:#fff;
          padding-right:20px;
          font-size:16px;
          color:#b4b4b4;
          font-weight:bold;
          display:inline-block;
        }
        &:after {
          content:'';
          height:1px;
          background-color:#ddd;
          position:absolute;
          right:0;
          left:0;
          top:50%;
          z-index:-1;
        }
      }
      ul {
        width:100%;
        display:table;
        li {
          width:50%;
          padding:0 7px;
          display:table-cell;
          a {
            height:50px;
            background-color:#eee;
            border-radius:50px;
            padding-top:9px;
            text-align:center;
            display:block;
          }
          &:first-child {padding-left:0;}
          &:last-child {padding-right:0;}
        }
      }
    }
    .menu {
      @extend .row;
      border-top:1px solid #ddd;
      margin-bottom:20px;
      padding-top:20px;
      .sidecanvas-link {
        @extend .zone-12;
        @extend .d-space-between;
        @extend .float-start;
        padding:10px 0;
        font-size:16px;
        font-weight:bold;
        position:relative;
        &:after {
          content:'';
          width:20px;
          height:20px;
          background:url('#{$IMG_PATH}/common/ico-arrow.png') no-repeat;
          background-size:20px auto;
          margin-top:-10px;
          display:inline-block;
          position:absolute;
          right:0;
          top:50%;
        }
      }
    }
    .lang {border-top:1px solid #ddd;padding:20px 0;
      li {display:inline-block;
        a {margin-left:15px;padding-left:15px;font-size:16px;font-weight:bold;color:#b4b4b4;position:relative;
          &:before {content:'';width:1px;height:10px;background-color:#e6e6e6;margin-top:-5px;position:absolute;left:0;top:50%;}
        }
        &.active a {color:#000;}
        &:first-child a {margin:0;padding:0;}
        &:first-child a:before {display:none;}
      }
    }
  }
}

.sidecanvas.hiding,
.sidecanvas.show {
  visibility:visible;
}

.sidecanvas.showing {
  visibility:visible;
  transform:none;
}

.sidecanvas.show:not(.hiding),
.sidecanvas.showing {
  transform:none;
}

.sidecanvas-bg {
  width:100vw;
  height:100vh;
  background-color:#000;
  opacity:0.5;
  position:fixed;
  top:0;
  left:0;
  z-index:1040;
  display:none;
}

.area-intro {
  @extend .row;
  @extend .align-items-center;
  height:420px;
  background:#78798e url('#{$IMG_PATH_KO}/main/img-visual.jpg') no-repeat center right;
  background-size:cover;
  margin-top:60px;
  overflow:hidden;
  :lang(en) & {
    background:#78798e url('#{$IMG_PATH_EN}/main/img-visual.jpg') no-repeat center right;
    background-size:cover;
  }
}
.section-intro {
  @extend .zone-12;
  margin:-60px auto 0;
  padding:0 15px 0 30px;
  color:#fff;
  .intro-content {
    h2:nth-child(1) {
      padding-bottom:5px;
      font-size:18px;
      font-weight:bold;
    }
    h2:nth-child(2) {
      padding-bottom:10px;
      font-size:30px;
      font-weight:bold;
    }
  }
}

.area-introduce {
  background-color:#f8f6ff;
  overflow:hidden;
}
.section-introduce {
  @extend .row;
  padding:50px 15px;

  .introduce-img {
    @extend .zone-12;
    padding-bottom:15px;

    img {
      max-width:40px;
      height:40px;
    }
  }

  .introduce-content {
    @extend .zone-12;

    div {
      padding-bottom:20px;
    }

    h2 {
      font-weight:bold;
      font-size:20px;
      padding-bottom:10px;
    }
  }
}

.area-service {
  overflow:hidden;
}
.section-service {
  padding:50px 15px;
  text-align:center;

  h2 {
    margin-bottom:10px;
    font-size:24px;
    font-weight:bold;
  }

  .service-description {
    margin-bottom:20px;
  }

  .service-img-group {
    margin-bottom:30px;

    img {
      width:24px;
      height:24px;
      margin-left:10px;
    }

    img:first-child {
      margin-left:0;
    }
  }

  .service-diagram-vertical {
    @extend .zone-12;
    @extend .d-flex;
    @extend .flex-column;
    @extend .align-items-center;
    color:#fff;

    div {
      padding:0 15px;
    }
    div:first-child {
      @extend .g-center;
      @extend .row;
      flex-direction:column;
      width:270px;
      height:270px;
      border-radius:50%;
      background-color:rgba(127, 118, 253, .8);
      strong {
        font-size:20px;
        padding-bottom:6px;
      }
    }

    div:last-child {
      @extend .g-center;
      @extend .row;
      flex-direction:column;
      width:270px;
      height:270px;
      margin-top:-52px;
      border-radius:50%;
      background-color:rgba(241, 102, 97, .8);

      strong {
        font-size:20px;
        padding-bottom:5px;
      }
    }
  }

  .service-diagram-horizontal {
    @extend .zone-12;
    @extend .d-none;
    @extend .flex-column;
    @extend .align-items-center;
    color:#fff;
    div {
      padding:0 40px;
    }
    div:first-child {
      @extend .g-center;
      border-radius:50%;
      background-color:rgba(127, 118, 253, .8);
    }
    div:last-child {
      @extend .g-center;
      border-radius:50%;
      background-color:rgba(241, 102, 97, .8);
    }
  }
}

.area-worry {
  background-color:#eee;
  overflow:hidden;
}
.section-worry {
  padding:50px 15px;
  text-align:center;
  .txt {
    margin-bottom:30px;
    .worry-title {
      margin-bottom:10px;
      font-size:24px;
      font-weight:bold;
      span {
        color:#7f76fd;
      }
    }
    .worry-content {
      margin-bottom:10px;
    }
    .worry-comment {
      font-size:13px;
    }
  }
}

.worry-youngboy {
  @extend .zone-12;
  margin-bottom:30px;

  .worry-youngboy-wrap {
    @extend .row;
  }

  .worry-youngboy-bubble {
    @extend .zone-12;
  }

  .youngboy-bubble-bg {
    @extend .row;
    background-color:#fff;
    border-radius:20px;
    margin-right:20px;
    padding:20px;
    text-align:right;
    position:relative;
    &::after {
      content:'';
      width:20px;
      height:34px;
      background:url('#{$IMG_PATH}/main/ico-worry-right.png') no-repeat;
      background-size:20px auto;
      display:inline-block;
      position:absolute;
      right:-20px;
    }
  }

  .youngboy-bubble-content {
    padding-right:60px;
    .bubble-content-wrap {
      strong {
        margin-bottom:10px;
        font-size:20px;
        display:block;
      }
    }
  }
  .youngboy-img {
    width:auto;
    position:absolute;
    right:20px;
    top:20px;
    img {
      width:50px;
    }
  }

  .worry-youngboy-btn {
    @extend .zone-12;
  }

  .youngboy-btn-wrap {
    @extend .row;
    @extend .justify-content-center;
    font-size:16px;
    transform:translate3d(0, 0, 0);
    padding-top:20px;

    div:nth-child(1) {
      @extend .zone-auto;
      margin-bottom:5px;

      span {
        width:fit-content;
        background-color:#f16661;
        border-radius:60px;
        margin-right:4px;
        padding:9px 19px;
        color:#fff;
        display:block;
        transform:translate3d(0, 0, 0);
      }
    }

    div:nth-child(2) {
      @extend .zone-auto;
      margin-bottom:5px;

      span {
        border-radius:60px;
        background-color:#505050;
        padding:9px 19px;
        color:#fff;
        width:fit-content;
        transform:translate3d(0, 0, 0);
        margin-left:1px;
        display:block;
      }
    }

    div:nth-child(3) {
      @extend .zone-auto;
      margin-bottom:5px;

      span {
        border-radius:60px;
        background-color:#505050;
        padding:9px 19px;
        color:#fff;
        width:fit-content;
        transform:translate3d(0, 0, 0);
        margin-left:4px;
        display:block;
      }
    }
  }
}

.worry-younggirl {
  @extend .zone-12;
  margin-bottom:30px;

  .worry-younggirl-wrap {
    @extend .row;
  }

  .worry-younggirl-bubble {
    @extend .zone-12;
  }

  .younggirl-bubble-bg {
    @extend .row;
    background-color:#fff;
    border-radius:20px;
    margin-left:20px;
    padding:20px;
    text-align:left;
    position:relative;
    &::after {
      content:'';
      width:20px;
      height:34px;
      background:url('#{$IMG_PATH}/main/ico-worry-left.png') no-repeat;
      background-size:20px auto;
      display:inline-block;
      position:absolute;
      left:-20px;
    }
  }

  .younggirl-bubble-content {
    padding-left:60px;
    .bubble-content-wrap {
      strong {
        margin-bottom:10px;
        font-size:20px;
        display:block;
      }
    }
  }

  .younggirl-img {
    width:auto;
    position:absolute;
    left:20px;
    top:20px;
    img {
      width:50px;
    }
  }

  .worry-younggirl-btn {
    @extend .zone-12;
  }

  .younggirl-btn-wrap {
    @extend .row;
    @extend .justify-content-center;
    font-size:16px;
    transform:translate3d(0, 0, 0);
    padding-top:20px;

    div:nth-child(1) {
      @extend .zone-auto;
      margin-bottom:5px;

      span {
        border-radius:60px;
        background-color:#ffb453;
        padding:9px 19px;
        color:#fff;
        width:fit-content;
        transform:translate3d(0, 0, 0);
        margin-right:4px;
        display:block;
      }
    }

    div:nth-child(2) {
      @extend .zone-auto;
      margin-bottom:5px;

      span {
        border-radius:60px;
        background-color:#505050;
        padding:9px 19px;
        color:#fff;
        width:fit-content;
        transform:translate3d(0, 0, 0);
        margin-left:1px;
        display:block;
      }
    }

    div:nth-child(3) {
      @extend .zone-auto;
      margin-bottom:5px;

      span {
        border-radius:60px;
        background-color:#505050;
        padding:9px 19px;
        color:#fff;
        width:fit-content;
        transform:translate3d(0, 0, 0);
        margin-left:4px;
        display:block;
      }
    }
  }
}

.worry-oldman {
  @extend .zone-12;
  margin-bottom:30px;

  .worry-oldman-wrap {
    @extend .row;
  }

  .worry-oldman-bubble {
    @extend .zone-12;
  }

  .oldman-bubble-bg {
    @extend .row;
    background-color:#fff;
    border-radius:20px;
    margin-right:20px;
    padding:20px;
    text-align:right;
    position:relative;
    &::after {
      content:'';
      width:20px;
      height:34px;
      background:url('#{$IMG_PATH}/main/ico-worry-right.png') no-repeat;
      background-size:20px auto;
      display:inline-block;
      position:absolute;
      right:-20px;
    }
  }

  .oldman-bubble-content {
    padding-right:60px;
    .bubble-content-wrap {
      strong {
        margin-bottom:10px;
        font-size:20px;
        display:block;
      }
    }
  }

  .oldman-img {
    width:auto;
    position:absolute;
    right:20px;
    top:20px;
    img {
      width:50px;
    }
  }

  .worry-oldman-btn {
    @extend .zone-12;
  }

  .oldman-btn-wrap {
    @extend .row;
    @extend .justify-content-center;
    font-size:16px;
    transform:translate3d(0, 0, 0);
    padding-top:20px;

    div:nth-child(1) {
      @extend .zone-auto;
      margin-bottom:5px;

      span {
        border-radius:60px;
        background-color:#7f76fd;
        padding:9px 19px;
        color:#fff;
        width:fit-content;
        transform:translate3d(0, 0, 0);
        margin-right:4px;
        display:block;
      }
    }

    div:nth-child(2) {
      @extend .zone-auto;
      margin-bottom:5px;

      span {
        border-radius:60px;
        background-color:#505050;
        padding:9px 19px;
        color:#fff;
        width:fit-content;
        transform:translate3d(0, 0, 0);
        margin-left:1px;
        display:block;
      }
    }

    div:nth-child(3) {
      @extend .zone-auto;
      margin-bottom:5px;

      span {
        border-radius:60px;
        background-color:#505050;
        padding:9px 19px;
        color:#fff;
        width:fit-content;
        transform:translate3d(0, 0, 0);
        margin-left:4px;
        display:block;
      }
    }

    div:nth-child(4) {
      @extend .zone-auto;
      margin-bottom:5px;

      span {
        border-radius:60px;
        background-color:#505050;
        padding:9px 19px;
        color:#fff;
        width:fit-content;
        transform:translate3d(0, 0, 0);
        margin-left:4px;
        display:block;
      }
    }
  }
}

.worry-oldwoman {
  @extend .zone-12;
  margin-bottom:0;

  .worry-oldwoman-wrap {
    @extend .row;
  }

  .worry-oldwoman-bubble {
    @extend .zone-12;
  }
  .oldwoman-bubble-bg {
    @extend .row;
    background-color:#fff;
    border-radius:20px;
    margin-left:20px;
    padding:20px;
    text-align:left;
    position:relative;
    &::after {
      content:'';
      width:20px;
      height:34px;
      background:url('#{$IMG_PATH}/main/ico-worry-left.png') no-repeat;
      background-size:20px auto;
      display:inline-block;
      position:absolute;
      left:-20px;
    }
  }

  .oldwoman-bubble-content {
    padding-left:60px;
    .bubble-content-wrap {
      strong {
        margin-bottom:10px;
        font-size:20px;
        display:block;
      }
    }
  }

  .oldwoman-img {
    width:auto;
    position:absolute;
    left:20px;
    top:20px;
    img {
      width:50px;
    }
  }

  .worry-oldwoman-btn {
    @extend .zone-12;
  }

  .oldwoman-btn-wrap {
    @extend .row;
    @extend .justify-content-center;
    font-size:16px;
    transform:translate3d(0, 0, 0);
    padding-top:20px;

    div:nth-child(1) {
      @extend .zone-auto;
      margin-bottom:5px;

      span {
        width:fit-content;
        background-color:#f16661;
        border-radius:60px;
        margin-right:4px;
        padding:9px 19px;
        color:#fff;
        display:block;
        transform:translate3d(0, 0, 0);
      }
    }

    div:nth-child(2) {
      @extend .zone-auto;
      margin-bottom:5px;

      span {
        border-radius:60px;
        background-color:#7f76fd;
        padding:9px 19px;
        color:#fff;
        width:fit-content;
        transform:translate3d(0, 0, 0);
        margin-left:1px;
        display:block;
      }
    }

    div:nth-child(3) {
      @extend .zone-auto;
      margin-bottom:5px;

      span {
        border-radius:60px;
        background-color:#7f76fd;
        padding:9px 19px;
        color:#fff;
        width:fit-content;
        transform:translate3d(0, 0, 0);
        margin-left:4px;
        display:block;
      }
    }

    div:nth-child(4) {
      @extend .zone-auto;
      margin-bottom:5px;

      span {
        border-radius:60px;
        background-color:#505050;
        padding:9px 19px;
        color:#fff;
        width:fit-content;
        transform:translate3d(0, 0, 0);
        margin-left:3px;
        display:block;
      }
    }
  }
}

.area-solve {
  background:url('#{$IMG_PATH_KO}/main/img-keypoint.jpg') no-repeat center;
  background-size:cover;
  overflow:hidden;
  :lang(en) & {
    background:url('#{$IMG_PATH_EN}/main/img-keypoint.jpg') no-repeat center;
    background-size:cover;
  }
}
.section-solve {
  @extend .row;
  @extend .d-block;
  height:400px;
  padding:50px 15px 50px 30px;
  color:#fff;

  h2 {
    margin-bottom:10px;
    font-size:24px;
    font-weight:bold;
  }
}

.area-advantage {overflow:hidden;}
.section-advantage {
  padding:50px 15px 0 15px;
  .advantage {
    margin-bottom:50px;
    .img {
      margin-bottom:30px;
      text-align:center;
      img {
        max-width:375px;
      }
    }
    .txt {
      .advantage-title {
        margin-bottom:5px;
        font-size:24px;
        font-weight:bold;
      }
      .advantage-subtitle {
        margin-bottom:10px;
        font-size:20px;
        color:#7f76fd;
        font-weight:bold;
      }
    }
  }
}

.area-history {
  background-color:#eee;
  margin-bottom:50px;
  overflow:hidden;
}
.section-history {
  padding:50px 15px;
  text-align:center;
  .history-title {
    margin-bottom:10px;
    h2 {
      font-size:24px;
      font-weight:bold;
    }
  }
  .history-description {
    margin-bottom:30px;
  }
  .history-box {
    border-radius:10px;
    >ul>li {
      background-color:#f8f8f8;
      margin-bottom:20px;
      padding:30px;
      text-align:left;
      strong {
        margin-bottom:5px;
        font-size:20px;
        display:block;
      }
      .day {
        color:#505050;
      }
      >ul>li {
        padding-left:14px;
        font-weight:bold;
        color:#707070;
        position:relative;
        &:before {content:'';width:4px;height:4px;background-color:#707070;border-radius:4px;margin-left:-14px;display:inline-block;position:absolute;left:auto;top:8px;}
        >ul {padding-top:10px;
          >li {
            padding-left:14px;
            color:#505050;
            font-weight:normal;
            position:relative;
            &:before {content:'';width:4px;height:1px;background-color:#505050;margin-left:-14px;display:inline-block;position:absolute;left:auto;top:8px;}
          }
        }
      }
      &:nth-child(even) {
        background-color:#fff;
      }
    }
  }
}

.service {text-align:center;position:relative;
  .top {margin-bottom:30px;
    strong {margin-bottom:10px;font-size:24px;display:block;
      span {color:#7f76fd;}
    }
  }
  .fix-tabs {width:100%;height:60px;background-color:#fff;margin-bottom:50px;
    .fix-tabs-container {padding:0;text-align:center;overflow-x:auto;-ms-overflow-style:none;scrollbar-width:none;
      ul {display:flex;
        li {padding-right:10px;flex-grow:1;
          .fix-tab {width:100%;height:50px;background-color:#eee;border-radius:50px;padding:0 20px;font-weight:bold;line-height:50px;color:#000;text-transform:uppercase;white-space:nowrap;transition:all 0.5s ease;display:inline-block;
            &.active {background-color:#7f76fd;color:#fff !important;transition:all 0.5s ease;}
          }
        }
      }
      &::-webkit-scrollbar {display:none;}
      &.fix-tabs-container-top {width:100%;background-color:#fff;padding:10px 0;position:fixed;top:0;left:0;z-index:99999;
        &.fix-tabs-container-bottom {position:absolute;}
      }
    }
  }
  .fix-slides {
    .fix-slide {margin-bottom:100px;
      .txt {margin-bottom:30px;text-align:center;
        strong {margin-bottom:10px;font-size:20px;line-height:1.2;display:block;
          span {color:#7f76fd;}
        }
      }
      .img {
        .swiper-container {width:186px;margin:0 auto;padding-bottom:50px;
          .swiper-slide {padding:0 10px;
            .img-service {border-radius:30px;box-shadow:0 3px 10px 0 rgba(0, 0, 0, 0.35);}
          }
          .swiper-pagination-bullet {background-color:#eee;opacity:1;}
          .swiper-pagination-bullet-active {background-color:#000;}
        }
      }
    }
  }
}

.area-shopping {
  background:url('#{$IMG_PATH_KO}/main/img-point.jpg') no-repeat center;
  background-size:cover;
  overflow:hidden;
}
.section-shopping {
  height:400px;
  padding-left:15px;

  .shopping-title {
    margin:0 auto;
    padding-top:50px;
    font-size:24px;
    font-weight:bold;
    color:#fff;
  }
}

.area-point {
  overflow:hidden;
}
.section-point {
  @extend .row;
  @extend .align-items-center;
  max-width:375px;
  margin:0 auto;
  padding:50px 15px;
  text-align:center;
  .point-accumulate-img {
    margin-bottom:30px;
  }

  .point-accumulate-title {
    margin-bottom:50px;

    img {
      width:60px;
      height:60px;
      margin-bottom:10px;
    }

    .title {
      font-weight:bold;
      color:#7f76fd;
      font-size:24px;
      margin-bottom:10px;

      span {
        color:#000;
      }
    }
  }

  .point-free-img {
    margin-bottom:30px;
  }

  .point-free-title {

    img {
      width:60px;
      height:60px;
      margin-bottom:10px;
    }

    .title {
      margin-bottom:10px;
      font-size:24px;
      color:#000;
      font-weight:bold;

      span {
        color:#7f76fd;
      }
    }
  }
}

.area-pro {
  background-color:#eee;
  overflow:hidden;
}
.section-pro {
  padding:50px 15px;
  text-align:center;
  .pro-title {
    margin-bottom:20px;
    font-weight:bold;
    font-size:24px;
  }
  .button {
    width:150px;
    height:50px;
    background-color:#7f76fd;
    border-radius:50px;
    margin:0 auto 35px;
    font-weight:bold;
    color:#fff;
    line-height:50px;
    display:block;
    box-shadow:0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .swiper {
    .swiper-wrapper {
      padding:5px;
      transition-timing-function:linear;
    }
    .swiper-slide {
      width:200px;
      margin-right:20px;
      .card {
        width:100%;
        background-color:#fff;
        padding:35px 0;
        border-radius:20px;
        box-shadow:0 0 6px 0 rgba(0, 0, 0, 0.2);

        img {
          width:140px;
          height:140px;
          border-radius:100%;
          margin-bottom:20px;
        }

        .card-name {
          font-size:20px;
          font-weight:bold;
        }
      }
      &.last .card {
        background-color:#e3e3e3;
        cursor:pointer;
      }
    }
  }
}

.area-counsel {
  overflow:hidden;
}
.section-counsel {
  padding:50px 15px;
  text-align:center;

  .counsel-intro-wrap {
    .counsel-intro-title {
      margin-bottom:50px;
      strong {
        margin-bottom:10px;
        font-size:24px;
        display:block;
      }
    }
  }

  .counsel-textchat-wrap {
    @extend .row;
    @extend .align-items-center;
    margin-bottom:50px;

    .counsel-textchat-description {
      margin-bottom:30px;

      img {
        width:60px;
        height:60px;
        margin-bottom:10px;
      }

      strong {
        margin-bottom:10px;
        font-size:24px;
        display:block;
      }
    }
    .counsel-textchat-img {
      text-align:center;
      img {
        max-width:375px;
      }
    }
  }

  .counsel-voicechat-wrap {
    @extend .row;
    @extend .align-items-center;
    .counsel-voicechat-description {
      margin-bottom:30px;
      img {
        width:60px;
        height:60px;
        margin-bottom:10px;
      }
      strong {
        margin-bottom:10px;
        font-size:24px;
        display:block;
      }
    }
    .counsel-voicechat-img {
      text-align:center;
      img {
        max-width:375px;
      }
    }
  }
}

.area-client {
  background-color:#eee;
  overflow:hidden;
}
.section-client {
  @extend .row;
  padding:50px 15px;
  text-align:center;

  .client-intro-title {
    font-weight:bold;
    font-size:24px;
    margin-bottom:30px;

    span {
      color:#7f76fd;
    }
  }

  .client-card-group {
    @extend .row;
  }

  .client-card-wrap {
    margin-bottom:20px;
  }

  .client-card {
    @extend .text-start;
    background-color:#fff;
    border-radius:30px;
    padding:30px;
  }
  .client-icon-img {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-end;
    margin-bottom:10px;
    img:nth-of-type(1) {
      width:30px;
      height:30px;
    }

    img:nth-of-type(2) {
      max-width:60px;
      height:60px;
    }
  }

  .client-card-title {
    font-weight:bold;
    font-size:20px;
    margin-bottom:10px;

    span {
      color:#7f76fd;
    }
  }

  .client-card-description {
    margin-bottom:10px;
  }

  .close-quotes {
    margin-bottom:20px;

    img {
      width:30px;
      height:30px;
    }
  }
}

.area-download {
  background-color:#f3f0fe;
  overflow:hidden;
}
.section-download {
  padding:50px 15px;
  text-align:center;

  .download-title-wrap {
    @extend .row;
    font-weight:bold;
    margin-bottom:20px;
    flex-direction:column;
    font-size:24px;

    img {
      margin:0 auto;
      max-width:80px;
      height:80px;
      margin-bottom:20px;
    }

    strong {
      color:#7f76fd;
      display:contents;
    }
  }

  .download-btn-wrap {
    ul {
      width:340px;
      margin:0 auto;
      display:table;
      li {
        padding:0 10px;
        display:table-cell;
        vertical-align:middle;
        a {
          width:150px;
          height:50px;
          background-color:#000;
          border-radius:50px;
          padding:0 20px;
          display:table-cell;
          vertical-align:middle;
          box-shadow:0 6px 15px 0 rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}

.area-thirdparty {
  overflow:hidden;
}
.section-thirdparty {
  padding:50px 15px;
  text-align:center;

  .thirdparty-title-wrap {
    margin-bottom:40px;
    .thirdparty-title {
      margin-bottom:10px;
      font-size:24px;
      font-weight:bold;
    }
    .thirdparty-description {
      span {
        display:block;
      }
    }
  }
  .cont ul {overflow:hidden;
    li {width:50%;height:160px;margin-bottom:20px;padding:0 10px;float:left;display:table;
      .ele {width:100%;height:100%;background-color:#eaeaea;border:2px solid #eaeaea;border-radius:10px;overflow:hidden;display:table-cell;vertical-align:middle;
        img {width:150px;height:auto;}
        a {width:100%;height:100%;padding:20px 10px;text-align:left;font-size:18px;font-weight:bold;color:#fff;display:block;position:relative;
          &:after {content:'';width:24px;height:24px;background:url('#{$IMG_PATH}/main/ico-arrow.png') 0 -30px no-repeat;background-size:30px auto;position:absolute;bottom:30px;right:20px;}
        }
      }
      &:nth-child(odd) {padding-left:0;}
      &:nth-child(even) {padding-right:0;}
      &.else .ele {background-color:#fff;}
      &.sdmall .ele {background-color:#fc6658;border:0;}
      &.yakook .ele {background-color:#9966cc;border:0;}
      &.dofit .ele {background-color:#44c3f8;border:0;}
    }
  }
}

/* master */
.masters {
  .container {
    width:100%;
    padding:110px 15px 50px 15px;
    .tit {
      margin-bottom:50px;
      text-align:center;

      > span {
        font-size:18px;
        font-weight:bold;
        display:block;
      }

      strong {
        font-size:24px;
        display:block;
      }
    }
    .cont {
      ul.grid {
        padding-left:0;

        li.grid-item {
          width:100%;
          margin-bottom:20px;
          float:left;

          .ele {
            background-color:#fff;
            padding:0 20px 60px 20px;
            box-shadow:0 3px 9px 0 rgba(0, 0, 0, 0.2);

            .photo {
              height:250px;
              margin:0 -20px 20px -20px;
              overflow:hidden;

              img {
                width:100%;
              }
            }

            strong {
              font-size:20px;
            }

            .info {
              margin-bottom:10px;
            }

            ul {
              li {
                margin-left:20px;
                list-style:outside;
              }
            }
          }
        }

        &:after {
          content:'';
          display:block;
          clear:both;
        }
      }
    }
  }
}

/* footer */
.section {
  margin-bottom:50px;
}

.footer {
  .container {
    width:100%;
    padding:0 15px;
  }

  ul {
    padding-left:0;
  }

  .info {
    background-color:#eee;
    margin-bottom:50px;
    padding:50px 0;

    .logo {
      margin-bottom:20px;

      img {
        width:140px;
        height:auto;
      }

      .container {
        max-width:1080px;
        margin:0 auto;
      }
    }

    ul li {
      margin-bottom:10px;

      .tit {
        margin-bottom:15px;
        font-size:18px;
        font-weight:bold;
      }

      ul {
        li {
          font-size:15px;
          overflow:hidden;
        }
      }

      .site {
        position:relative;

        button {
          width:100%;
          background-color:#fff;
          border-radius:10px;
          padding:15px 20px;
          text-align:left;
          font-size:14px;
          color:#666;
          position:relative;
          border:0;
          span {
            width:2px;
            height:14px;
            background-color:#000;
            margin-top:-7px;
            display:block;
            position:absolute;
            top:50%;
            right:26px;
            transition:all 0.2s;

            &:after {
              content:'';
              width:14px;
              height:2px;
              background-color:#000;
              position:absolute;
              top:6px;
              left:-6px;
            }
          }

          &.active span {
            transform:rotate(90deg);
          }
        }

        ul {
          width:100%;
          background-color:#fff;
          border-radius:10px;
          border:1px solid #eee;
          padding:20px 20px 10px 20px;
          position:absolute;
          left:0;
          bottom:50px;
          z-index:9;
          display:none;

          &.active {
            display:block;
          }
        }
      }

      &.info2 {
        margin-bottom:30px;
      }
    }
  }

  .copyright {
    padding-bottom:50px;

    ul {
      li {
        margin-bottom:10px;
        font-size:15px;
        &:first-child {
          letter-spacing:0;
        }
      }
    }
  }
}

//privacy
.privacy-policy {padding:50px 0;
  h3 {font-size:25px;font-weight:bold;}
  .tit {margin-bottom:20px;padding-top:20px;font-size:20px;font-weight:bold;}
  p {color:#757575;}
  table.tbl {width:100%;border-collapse:collapse;border-spacing:0;border-right:1px solid #e5e5e5;border-top:1px solid #e5e5e5;margin-bottom:10px;
    th {background-color:#fafafa;border-bottom:1px solid #e5e5e5;border-left:1px solid #e5e5e5;padding:10px 0 7px;color:#757575;text-align:center;}
    td {border-bottom:1px solid #e5e5e5;border-left:1px solid #e5e5e5;padding:10px 10px 7px;color:#757575;}
  }
  .ul li {margin-bottom:10px;color:#757575;
    .ul2 {padding:10px 0 0 20px;}
  }
}

/* error */
body.error {height:100%;background-color:#f0f0f0;
  .box {width:100%;height:100%;margin:0 auto;text-align:center;display:table;
    .container {display:table-cell;vertical-align:middle;
      h1.num {font-size:90px;line-height:1.2;float:none;}
      h2.tit {margin-bottom:20px;font-size:28px;}
      .cont {margin-bottom:40px;}
      .btn-wrap {
        ul {width:100%;display:table;
          li {width:50%;padding:0 10px;display:table-cell;
            a, button {width:100%;height:40px;line-height:40px;color:#fff;display:block;}
            .btn-go-main {background-color:#000;}
            .btn-go-prev {background-color:#888;}
          }
        }
      }
    }
  }
}

/* 애니메이션 키프레임 */
.ani-slide-top {
  animation:slide-to-top 1.2s ease-out forwards;
}

.ani-slide-bottom {
  animation:slide-to-bottom 1.2s ease-out forwards;
}

@keyframes slide-to-top {
  0% {
    bottom:1%;
  }
  100% {
    bottom:11%;
  }
}

@keyframes slide-to-bottom {
  0% {
    bottom:11%;
  }
  100% {
    bottom:1%;
  }
}

@keyframes fade-in-left {
  0% {
    transform:translateX(12.5vw);
    opacity:0
  }
  100% {
    transform:translateX(0);
    opacity:1
  }
}

@keyframes flip-in-hor-bottom {
  0% {
    transform:rotateX(80deg);
    opacity:0
  }
  100% {
    transform:rotateX(0);
    opacity:1
  }
}

@keyframes scalebig {
  0% {
    scale:1
  }
  100% {
    scale:1.05
  }
}

@keyframes scalesmall {
  0% {
    scale:1.05
  }
  100% {
    scale:1
  }
}

@keyframes test {
  from {
    margin-left:100%;
    width:300%
  }
  to {
    margin-left:0;
    width:100%
  }
}

@keyframes text-focus-in {
  0% {
    filter:blur(12px);
    opacity:0
  }
  100% {
    filter:blur(0);
    opacity:1
  }
}

@keyframes focus-in-contract-bck {
  0% {
    letter-spacing:1em;
    transform:translateZ(300px);
    filter:blur(12px);
    opacity:0
  }
  100% {
    transform:translateZ(12px);
    filter:blur(0);
    opacity:1
  }
}

@keyframes color-change-3x {
  0% {
    background:#7f76fd
  }
  50% {
    background:#96c
  }
  100% {
    background:#f26b91
  }
}

/* popup */
.layer-pop {background-color:#006bda;margin-left:-160px;position:absolute;left:50%;top:10%;z-index:9999;visibility:hidden;
  .cont img {max-width:320px;}
  form {background-color:#222;color:#fff;margin:0;padding-left:20px;line-height:50px;overflow:hidden;
    input {width:20px;height:20px;margin-right:10px;line-height:1;display:inline-block;vertical-align:middle;}
    a {background-color:#000;padding:0 20px;color:#fff;display:block;float:right;
      span {margin-right:5px;color:#777;}
    }
  }
}

@media (min-width:426px){
  /* master */
  .masters {
    .container {
      .cont ul.grid li.grid-item {
        width:50%;
        padding:0 10px;
      }
    }
  }
}

/* md */
@media (min-width:768px){
  /* common */
  .br {display:inline-block;}
  .br2 {display:block;}
  .float-download {display:block;}

  /* sidecanvas */
  .sidecanvas {
    display:none
  }
  .sidecanvas-bg {
    display:none;
  }

  /* footer */
  .section {
    margin-bottom:100px;
  }
  .footer {
    .info {
      ul li {
        &.info1, &.info2 {
          width:50%;
          padding-right:50px;
          float:left;
        }

        &.info2 {
          padding-top:43px;
        }
      }
    }

    .copyright {
      ul {
        li {
          margin:0 30px 0 0;
          display:inline-block;
        }

        .bar {
          height:13px;
          border-left:1px solid #ddd;
          margin-top:0;
          margin-left:15px;
          margin-right:15px;
          display:inline-block;
        }
      }
    }
  }

  /* gnb */
  .logo {width:200px;}
  .gnb {
    height:80px;

    .gnb-wrap {
      @extend .md-mx-auto;
      height:80px;
    }

    .gnb-content {
      @extend .md-zone-12;
      @extend .md-d-space-between;
    }

    .gnb-content-left {
      padding:0;
      h1 {
        display:inline-block;
        vertical-align:middle;
      }
      .menu {display:block;
        ul {
          li {
            .btn-menu {display:block;}
          }
        }
      }
    }

    .gnb-content-right {
      @extend .md-d-none;
    }
  }

  /* master */
  .masters {
    .container {
      padding:140px 30px 50px 30px;
    }
  }

  .area-intro {
    height:650px;
    background:#78798e url('#{$IMG_PATH_KO}/main/img-visual-w.jpg') no-repeat center;
    background-size:cover;
    margin-top:80px;
    :lang(en) & {
      background:#78798e url('#{$IMG_PATH_EN}/main/img-visual-w.jpg') no-repeat center;
      background-size:cover;
    }
  }
  .section-intro {
    margin-top:0;
    padding:0 30px 0 60px;
    .intro-content {
      h2 {
        padding-bottom:10px;
      }
    }
  }

  .section-introduce {
    padding-left:30px;
    padding-right:30px;

    .introduce-img {
      @extend .md-zone-1;
      margin-bottom:0 !important;
      display:flex;
      justify-content:flex-end;
      padding-right:2%;

      img {
        max-width:35px;
        height:35px;
      }
    }

    .introduce-content {
      @extend .md-zone-11;

      h2 {
        font-size:20px;
        margin-bottom:0 !important;
        padding-bottom:4px;
      }
    }
  }

  .section-service {
    padding-left:30px;
    padding-right:30px;
    .service-diagram-vertical {
      @extend .md-d-none;
    }
    .service-diagram-horizontal {
      @extend .md-d-flex;
      @extend .md-flex-row;
      @extend .md-justify-content-center;
      div:first-child {
        flex-direction:column;
        width:360px;
        height:150px;
        margin-right:-40px;
        border-radius:72px;
        strong {
          margin-bottom:5px;
          font-size:20px;
        }
      }
      div:last-child {
        flex-direction:column;
        width:360px;
        height:150px;
        border-radius:72px;
        strong {
          margin-bottom:5px;
          font-size:20px;
        }
      }
    }
  }

  .section-worry {
    padding-left:30px;
    padding-right:30px;
  }

  .worry-youngboy {
    margin-bottom:24px;

    .worry-youngboy-bubble {
      @extend .md-zone-9;
    }

    .youngboy-bubble-bg {
      padding:30px 20px;
    }
    .youngboy-bubble-content {
      .bubble-content-wrap {
        strong {
          font-size:19px;
        }
      }
    }
    .youngboy-img {
      top:30px;
    }

    .worry-youngboy-btn {
      @extend .md-zone-3;
      @extend .md-d-flex;
    }

    .youngboy-btn-wrap {
      @extend .md-flex-column;
      @extend .md-align-items-start;
      padding-left:5px;
      padding-top:0;

      div:nth-child(1) {
        @extend .md-zone-auto;

        span {
          @extend .md-pill-red;
          margin-right:0 !important;
        }
      }

      div:nth-child(2) {
        @extend .md-zone-auto;

        span {
          @extend .md-pill-dark;
          margin-left:0 !important;
        }
      }

      div:nth-child(3) {
        @extend .md-zone-auto;
        margin-bottom:0;

        span {
          @extend .md-pill-dark;
          margin-left:0;
        }
      }
    }
  }

  .worry-younggirl {
    margin-bottom:24px;

    .worry-younggirl-bubble {
      @extend .md-zone-9;
      @extend .md-order-1;
    }

    .younggirl-bubble-bg {
      padding:30px 20px;
    }

    .younggirl-bubble-content {
      .bubble-content-wrap {
        strong {
          font-size:19px;
        }
      }
    }
    .younggirl-img {
      top:30px;
    }

    .worry-younggirl-btn {
      @extend .md-zone-3;
      @extend .md-d-flex;
      @extend .md-order-0;
      @extend .md-justify-content-end;
    }

    .younggirl-btn-wrap {
      @extend .md-flex-column;
      @extend .md-align-items-start;
      padding-right:5px;
      padding-top:0;

      div:nth-child(1) {
        @extend .md-zone-auto;
        @extend .md-ms-auto;

        span {
          @extend .md-pill-yellow;
          margin-right:0 !important;
        }
      }

      div:nth-child(2) {
        @extend .md-zone-auto;
        @extend .md-ms-auto;

        span {
          @extend .md-pill-dark;
          margin-left:0 !important;
        }
      }

      div:nth-child(3) {
        @extend .md-zone-auto;
        @extend .md-ms-auto;
        margin-bottom:0;

        span {
          @extend .md-pill-dark;
        }
      }
    }
  }

  .worry-oldman {
    .worry-oldman-bubble {
      @extend .md-zone-9;
    }
    .oldman-bubble-bg {
      padding:30px 20px;
    }
    .oldman-bubble-content {
      .bubble-content-wrap {
        strong {
          font-size:19px;
        }
      }
    }
    .oldman-img {
      top:30px;
    }

    .worry-oldman-btn {
      @extend .md-zone-3;
      @extend .md-d-flex;
    }

    .oldman-btn-wrap {
      @extend .md-align-items-start;
      @extend .md-justify-content-start;
      @extend .md-align-content-start;
      padding-left:5px;
      padding-top:0;

      div:nth-child(1) {
        @extend .md-zone-auto;
        @extend .md-zone-12;

        span {
          @extend .md-pill-blue;
          @extend .md-float-start;
          margin-right:0 !important;
        }
      }

      div:nth-child(2) {
        @extend .md-zone-auto;

        span {
          @extend .md-pill-dark;
          margin-left:0 !important;
        }
      }

      div:nth-child(3) {
        @extend .md-zone-auto;

        span {
          @extend .md-pill-dark;
          margin-left:2px;
        }
      }

      div:nth-child(4) {
        @extend .md-zone-auto;
        margin-bottom:0;

        span {
          @extend .md-pill-dark;
          margin-left:0;
        }
      }
    }
  }

  .worry-oldwoman {
    margin-bottom:24px;

    .worry-oldwoman-bubble {
      @extend .md-zone-9;
      @extend .md-order-1;
    }

    .oldwoman-bubble-bg {
      padding:30px 20px;
    }

    .oldwoman-bubble-content {
      .bubble-content-wrap {
        strong {
          font-size:19px;
        }
      }
    }
    .oldwoman-img {
      top:30px;
    }

    .worry-oldwoman-btn {
      @extend .md-zone-3;
      @extend .md-d-flex;
      @extend .md-order-0;
      @extend .md-justify-content-end;
    }

    .oldwoman-btn-wrap {
      @extend .md-align-items-end;
      @extend .md-justify-content-end;
      @extend .md-align-content-start;
      padding-right:5px;
      padding-top:0;


      div:nth-child(1) {
        @extend .md-zone-12;
        @extend .md-ms-auto;

        span {
          @extend .md-pill-red;
          @extend .md-float-end;
          margin-right:0 !important;
        }
      }

      div:nth-child(2) {
        @extend .md-zone-auto;
        @extend .md-ms-auto;

        span {
          @extend .md-pill-blue;
          margin-left:0 !important;
        }
      }

      div:nth-child(3) {
        @extend .md-zone-auto;
        margin-left:2px;

        span {
          @extend .md-pill-blue;
          margin-left:0 !important;
        }
      }

      div:nth-child(4) {
        @extend .md-zone-auto;
        @extend .md-ms-auto;
        margin-bottom:0;

        span {
          @extend .md-pill-dark;
        }
      }
    }
  }

  .area-solve {
    background:url('#{$IMG_PATH_KO}/main/img-keypoint-w.jpg') no-repeat center;
    background-size:cover;
    :lang(en) & {
      background:url('#{$IMG_PATH_EN}/main/img-keypoint-w.jpg') no-repeat center;
      background-size:cover;
    }
  }

  .section-solve {
    @extend .md-d-flex;
    @extend .md-align-content-center;
    padding:0 300px 0 30px;
  }

  .section-advantage {
    .advantage {
      width:100%;
      display:table;
      .img {
        width:280px;
        margin-bottom:0;
        display:table-cell;
        vertical-align:top;
        table-layout:fixed;
        img {
          max-width:100%;
        }
      }
      .txt {
        padding-left:40px;
        display:table-cell;
        vertical-align:top;
      }
    }
  }

  .section-history {
    padding-left:30px;
    padding-right:30px;
    .history-box {
      >ul>li {
        text-align:center;
      }
    }
  }

  .service {
    .fix-tabs {height:90px;margin-bottom:150px;
      .fix-tabs-container ul {
        li {
          .fix-tab {line-height:1.2;display:flex;align-items:center;justify-content:center;
            :lang(en) & {
              display:table-cell;vertical-align:middle;
            }
          }
        }
      }
    }
    .fix-slides {
      .fix-slide {margin-bottom:200px;
        .img .swiper-container {width:372px;padding-bottom:0;
          .swiper-pagination-bullet {display:none;}
          &.service02,
          &.service03,
          &.service06,
          &.service07 {width:558px}
        }
      }
    }
  }

  .area-shopping {
    background:url('#{$IMG_PATH_KO}/main/img-point-w.jpg') no-repeat center;
    background-size:cover;
  }

  .section-shopping {
    width:100%;
    padding-left:30px;
    display:table;

    .shopping-title {
      padding-top:0;
      display:table-cell;
      vertical-align:middle;
    }
  }

  .section-point {
    max-width:100%;
    padding-left:30px;
    padding-right:30px;

    .point-accumulate-img {
      @extend .md-zone-6;
      margin-bottom:60px;
    }

    .point-accumulate-title {
      @extend .md-zone-6;
      @extend .md-text-start;
      margin-bottom:60px;
      padding-left:60px;
    }

    .point-free-img {
      @extend .md-zone-6;
      @extend .md-order-1;
      margin-bottom:0;
    }

    .point-free-title {
      @extend .md-zone-6;
      @extend .md-order-0;
      @extend .md-text-start;
      padding-left:60px;
    }
  }

  .section-pro {
  }

  .section-counsel {
    padding-left:30px;
    padding-right:30px;

    .counsel-intro-wrap {
    }

    .counsel-textchat-wrap {
      .counsel-textchat-description {
        @extend .md-zone-6;
        @extend .md-text-start;
        margin-bottom:0;
      }

      .counsel-textchat-img {
        @extend .md-zone-6;
        text-align:right;
        img {
          max-width:100%;
        }
      }
    }

    .counsel-voicechat-wrap {
      .counsel-voicechat-description {
        @extend .md-zone-6;
        @extend .md-order-1;
        @extend .md-text-start;
        margin-bottom:0;
      }

      .counsel-voicechat-img {
        @extend .md-zone-6;
        @extend .md-order-0;
        text-align:left;
        img {
          max-width:100%;
        }
      }
    }
  }

  .section-client {
    @extend .md-mx-auto;
    padding-left:30px;
    padding-right:30px;

    .client-intro-title {
      font-size:24px;
      margin-bottom:50px;
    }

    .client-card-group {
      max-width:940px;
      margin:0 auto;
    }

    .client-card-wrap {
      @extend .md-zone-6;
      margin-bottom:35px;
      padding:0 15px;
      &:nth-child(odd) {padding-left:0;}
      &:nth-child(even) {padding-right:0;}
    }

    .client-card {
      height:440px;
      :lang(en) & {
        height:520px;
      }
    }

    .card-top-left {
      @extend .md-me-auto;
      margin-left:0 !important;
    }

    .card-bottom-left {
      @extend .md-me-auto;
      margin-left:0 !important;
    }

    .card-top-right {
      @extend .md-ms-auto;
      margin-right:0 !important;
    }

    .card-bottom-right {
      @extend .md-ms-auto;
      margin-right:0 !important;
    }

    .client-icon-img {
      margin-bottom:10px;

      img:nth-of-type(1) {
        width:30px;
        height:30px;
      }

      img:nth-of-type(2) {
        max-width:60px;
        height:60px;
      }
    }

    .client-card-title {
      h2:nth-of-type(1) {
        margin-bottom:4px;
      }
    }

    .close-quotes {
      img {
        width:30px;
        height:30px;
      }
    }
  }

  .section-download {
    padding-left:30px;
    padding-right:30px;

    .download-title-wrap {
      @extend .md-justify-content-center;
    }
  }

  .section-thirdparty {
    padding-left:30px;
    padding-right:30px;
    .cont {max-width:700px;margin:0 auto;
      ul li {width:33.33%;height:210px;
        .ele a {padding:30px 20px;}
        &:nth-child(odd),
        &:nth-child(even) {padding:0 10px}
      }
    }
  }

  /* error */
  body.error .box {width:50%;}

  /* popup */
  .layer-pop {margin-left:0;position:absolute;left:10%;top:5%;
    .cont img {max-width:600px;}
    form {
      a {padding:0 60px;}
    }
  }
}

@media (min-width:852px){
  /* master */
  .masters {
    .container {
      .cont ul.grid li.grid-item {
        width:33.33%;
      }
    }
  }
}

/* xl */
@media (min-width:1200px) {
  /* common */
  .container {max-width:1080px;margin:0 auto;}

  /* footer */
  .footer {
    .container {
      max-width:1080px;
      margin:0 auto;
    }

    .info {
      ul {
        width:100%;
        display:table;

        li {
          &.info1, &.info2 {
            width:40%;
            padding-right:100px;
            display:table-cell;
            vertical-align:top;
            float:none;
          }

          &.family {
            width:20%;
            display:table-cell;
            vertical-align:top;
          }
        }
      }
    }
  }

  /* gnb */
  .gnb {
    padding:0;
    .gnb-wrap {
      @extend .xl-mx-auto;
      max-width:1020px;
      padding:0;
    }
    .gnb-content {
      @extend .xl-zone-12;
      @extend .xl-d-space-between;
    }
    .gnb-content-right {
      @extend .xl-d-none;
    }
  }

  /* master */
  .masters {
    .container {
      max-width:1080px;
      margin:0 auto;
      padding-bottom:100px;
      .tit {
        > span {
          font-size:30px;
        }
        strong {
          margin-bottom:10px;
          font-size:50px;
        }
        p {
          font-size:20px;
        }
      }
      .cont {
        ul.grid {
          li.grid-item {
            margin-bottom:40px;
            padding:0 20px;
          }
        }
      }
    }
  }

  .section-intro {
    max-width:1020px;
    margin:0 auto;
    padding:0;
    text-shadow:#000 0 0 1px;
    .intro-content {
      h2:nth-child(1) {
        font-size:30px;
      }
      h2:nth-child(2) {
        font-size:60px;
      }
      .sub-content {
        font-size:24px;
        h3 {
          font-size:24px;
        }
      }
    }
  }

  .section-introduce {
    max-width:1020px;
    margin:0 auto;
    padding:100px 0;

    .introduce-img {
      @extend .xl-zone-1;
      padding-left:0;

      img {
        max-width:60px;
        height:60px;
      }
    }

    .introduce-content {
      @extend .xl-zone-11;

      div {
        font-size:24px;
      }

      h2 {
        font-size:30px;
        padding-bottom:10px;
      }

      .extract {
        font-size:18px;
      }
    }
  }

  .section-service {
    max-width:1020px;
    margin:0 auto;
    padding:100px 0;

    h2 {
      margin-bottom:20px;
      font-size:40px;
    }

    .service-description {
      font-size:24px;
    }

    .service-img-group {
      margin-bottom:40px;

      img {
        width:40px;
        height:40px;
        margin-left:20px;
      }

      img:first-child {
        margin-left:0
      }
    }

    .service-diagram-vertical {
      @extend .xl-d-none;
    }
    .service-diagram-horizontal {
      @extend .xl-d-flex;
      @extend .xl-flex-row;
      div:first-child {
        width:540px;
        height:220px;
        margin-right:-60px;
        padding:0 60px;
        font-size:20px;
        border-radius:200px;
        strong {
          margin-bottom:10px;
          font-size:30px;
        }
      }
      div:last-child {
        width:540px;
        height:220px;
        font-size:20px;
        border-radius:200px;
        strong {
          margin-bottom:10px;
          font-size:30px;
        }
      }
    }
  }

  .section-worry {
    max-width:1020px;
    margin:0 auto;
    padding:100px 0;
    .txt {
      margin-bottom:60px;
      .worry-title {
        margin-bottom:20px;
        font-size:40px;
      }
      .worry-content {
        font-size:24px;
      }
      .worry-comment {
        font-size:18px;
      }
    }
  }

  .worry-youngboy {
    margin-bottom:40px;
    .worry-youngboy-bubble {
      @extend .xl-zone-9;
    }
    .youngboy-bubble-bg {
      padding:40px;
    }
    .youngboy-bubble-content {
      padding-right:110px;
      .bubble-content-wrap {
        font-size:18px;
        strong {
          font-size:24px;
        }
      }
    }
    .youngboy-img {
      right:40px;
      img {
        width:80px;
      }
    }

    .worry-youngboy-btn {
      @extend .xl-zone-3;
      @extend .xl-d-flex;
    }

    .youngboy-btn-wrap {
      @extend .xl-flex-column;
      @extend .xl-align-items-start;
      font-size:11px;
      padding-left:15px;
      padding-top:0 !important;


      div:nth-child(1) {
        @extend .xl-zone-auto;

        span {
          @extend .xl-pill-red;
        }
      }

      div:nth-child(2) {
        @extend .xl-zone-auto;

        span {
          @extend .xl-pill-dark;
        }
      }

      div:nth-child(3) {
        @extend .xl-zone-auto;

        span {
          @extend .xl-pill-dark;
        }
      }
    }
  }

  .worry-younggirl {
    margin-bottom:40px;
    .worry-younggirl-bubble {
      @extend .xl-zone-9;
      @extend .xl-order-1;
    }
    .younggirl-bubble-bg {
      padding:40px;
    }
    .younggirl-bubble-content {
      padding-left:110px;
      .bubble-content-wrap {
        font-size:18px;
        strong {
          font-size:24px;
        }
      }
    }
    .younggirl-img {
      left:40px;
      img {
        width:80px;
      }
    }
    .worry-younggirl-btn {
      @extend .xl-zone-3;
      @extend .xl-d-flex;
      @extend .xl-order-0;
      @extend .xl-justify-content-end;
    }

    .younggirl-btn-wrap {
      @extend .xl-flex-column;
      @extend .xl-align-items-start;
      font-size:11px;
      padding-right:13px;

      div:nth-child(1) {
        @extend .xl-zone-auto;
        @extend .xl-ms-auto;

        span {
          @extend .xl-pill-yellow;
        }
      }

      div:nth-child(2) {
        @extend .xl-zone-auto;
        @extend .xl-ms-auto;

        span {
          @extend .xl-pill-dark;
        }
      }

      div:nth-child(3) {
        @extend .xl-zone-auto;
        @extend .xl-ms-auto;

        span {
          @extend .xl-pill-dark;
        }
      }
    }
  }

  .worry-oldman {
    margin-bottom:40px;
    .worry-oldman-bubble {
      @extend .xl-zone-9;
    }
    .oldman-bubble-bg {
      padding:40px;
    }
    .oldman-bubble-content {
      padding-right:110px;
      .bubble-content-wrap {
        font-size:18px;
        strong {
          font-size:24px;
        }
      }
    }
    .oldman-img {
      right:40px;
      img {
        width:80px;
      }
    }

    .worry-oldman-btn {
      @extend .xl-zone-3;
      @extend .xl-d-flex;
    }

    .oldman-btn-wrap {
      @extend .xl-align-items-start;
      @extend .xl-justify-content-start;
      font-size:11px;
      padding-left:15px;

      div:nth-child(1) {
        @extend .xl-zone-auto;
        @extend .xl-zone-12;

        span {
          @extend .xl-pill-blue;
          @extend .xl-float-start;
        }
      }

      div:nth-child(2) {
        @extend .xl-zone-auto;

        span {
          @extend .xl-pill-dark;
        }
      }

      div:nth-child(3) {
        @extend .xl-zone-auto;

        span {
          @extend .xl-pill-dark;
          margin-left:4px;
        }
      }

      div:nth-child(4) {
        @extend .xl-zone-auto;

        span {
          @extend .xl-pill-dark;
        }
      }
    }
  }

  .worry-oldwoman {
    margin-bottom:0;
    .worry-oldwoman-bubble {
      @extend .xl-zone-9;
      @extend .xl-order-1;
    }
    .oldwoman-bubble-bg {
      padding:40px;
    }
    .oldwoman-bubble-content {
      padding-left:110px;
      .bubble-content-wrap {
        font-size:18px;
        strong {
          font-size:24px;
        }
      }
    }
    .oldwoman-img {
      left:40px;
      img {
        width:80px;
      }
    }

    .worry-oldwoman-btn {
      @extend .xl-zone-3;
      @extend .xl-d-flex;
      @extend .xl-order-0;
      @extend .xl-justify-content-end;
    }

    .oldwoman-btn-wrap {
      @extend .xl-align-items-end;
      @extend .xl-justify-content-end;
      font-size:11px;
      padding-right:13px;
      align-content:space-around;

      div:nth-child(1) {
        @extend .xl-zone-12;
        @extend .xl-ms-auto;

        span {
          @extend .xl-pill-red;
          @extend .xl-float-end;
        }
      }

      div:nth-child(2) {
        @extend .xl-zone-auto;
        @extend .xl-ms-auto;

        span {
          @extend .xl-pill-blue;
        }
      }

      div:nth-child(3) {
        @extend .xl-zone-auto;
        margin-left:4px;

        span {
          @extend .xl-pill-blue;
        }
      }

      div:nth-child(4) {
        @extend .xl-zone-auto;
        @extend .xl-ms-auto;

        span {
          @extend .xl-pill-dark;
        }
      }
    }
  }

  .area-solve {
    background:url('#{$IMG_PATH_KO}/main/img-keypoint-w.jpg') no-repeat center;
    background-size:cover;
    :lang(en) & {
      background:url('#{$IMG_PATH_EN}/main/img-keypoint-w.jpg') no-repeat center;
      background-size:cover;
    }
  }

  .section-solve {
    @extend .xl-d-flex;
    @extend .xl-align-content-center;
    max-width:1020px;
    margin:0 auto;
    padding:0 370px 0 0;
    h2 {
      margin-bottom:20px;
      font-size:40px;
    }
    h3 {
      font-size:24px;
    }
  }

  .section-advantage {
    max-width:1020px;
    margin:0 auto;
    padding:100px 0 50px 0;
    .txt {
      padding-left:80px;
      .advantage-title {
        font-size:30px;
      }
      .advantage-subtitle {
        font-size:24px;
      }
      .advantage-description {
        font-size:18px;
      }
    }
  }

  .area-history {margin-bottom:100px;}
  .section-history {
    max-width:1020px;
    margin:0 auto;
    padding:100px 0;
    .history-title {
      margin-bottom:20px;
      h2 {
        font-size:40px;
      }
    }
    .history-description {
      margin-bottom:60px;
      font-size:24px;
    }

    .history-box {
      max-width:1020px;
      margin:0 auto;
      >ul>li {
        strong {
          font-size:24px;
        }
        .day {
          font-size:15px;
        }
        >ul>li {
          font-size:18px;
          &:before {top:10px;}
          >ul>li {
            font-size:15px;
            &:before {top:10px;}
          }
        }
      }
    }
  }

  .service {
    .top {margin-bottom:60px;
      strong {margin-bottom:20px;font-size:40px;}
      p {font-size:24px;}
    }
    .fix-tabs {padding:10px;
      .fix-tabs-container ul li .fix-tab {height:70px;border-radius:70px;font-size:20px;
        :lang(en) & {
          font-size:16px;
        }
      }
    }
    .fix-slides {
      .fix-slide {
        .txt {margin-bottom:60px;
          strong {margin-bottom:20px;font-size:40px;}
          p {font-size:24px;}
        }
        .img .swiper-container {width:532px;
          &.service02,
          &.service03,
          &.service06,
          &.service07 {width:798px}
        }
      }
    }
  }

  .section-app {
    max-width:1020px;
    margin:0 auto;
    padding:100px 0;

    .app-title {
      font-size:40px;
      margin-bottom:30px;
    }

    .app-description {
      max-width:1060px;
      font-size:24px;
    }

    .app-swiper {padding-top:150px;
      .swiper-app-thumbs {
        .swiper-wrapper {transform:translate3d(0, 0, 0) !important;
          .swiper-slide {
            button {
              height:80px;
              border-radius:80px;
              line-height:80px;
              font-size:20px;
            }
            &:nth-child(5) {width:100px !important;}
            &:nth-child(6) {width:222px !important;}
            &.last button {font-size:19px;}
          }
        }
        .swiper-button-prev,
        .swiper-button-next {top:330px;}
      }
      .swiper-app-cont {
        .swiper-wrapper {
          .swiper-slide {
            .swiper {
              .swiper-wrapper {width:600px;margin:0 auto 80px;
                .swiper-slide {width:300px !important;
                  img {width:246px;border-radius:30px;}
                }
              }
              &.swiper-app2 .swiper-wrapper,
              &.swiper-app3 .swiper-wrapper,
              &.swiper-app6 .swiper-wrapper {width:900px;}
              .txt {
                strong {font-size:40px;}
                p {padding:0;font-size:24px;}
              }
            }
          }
        }
      }
    }
  }

  .area-shopping {
    background:url('#{$IMG_PATH_KO}/main/img-point-w.jpg') no-repeat center;
    background-size:cover;
  }

  .section-shopping {
    max-width:1020px;
    margin:0 auto;

    .shopping-title {
      max-width:1020px;
      font-size:40px;
    }
  }

  .section-point {
    max-width:1020px;
    padding:100px 0;

    .point-accumulate-img {
      @extend .xl-zone-6;
    }

    .point-accumulate-title {
      @extend .xl-zone-6;
      @extend .xl-text-start;
      font-size:18px;

      img {
        width:90px;
        height:90px;
        margin-bottom:20px;
      }

      .title {
        font-size:30px;
        margin-bottom:20px;
      }

      p {
        font-size:18px;
      }
    }

    .point-free-img {
      @extend .xl-zone-6;
      @extend .xl-text-start;
      @extend .xl-order-1;
    }

    .point-free-title {
      @extend .xl-zone-6;
      @extend .xl-text-start;
      @extend .xl-order-0;
      font-size:18px;
      img {
        width:90px;
        height:90px;
        margin-bottom:20px;
      }
      .title {
        margin-bottom:20px;
        font-size:30px;
      }
      h3 {
        font-size:18px;
      }
    }
  }

  .section-pro {
    padding:100px 0;
    .pro-title {
      margin-bottom:30px;
      font-size:40px;
    }
    .button {
      width:280px;
      height:80px;
      background-color:#7f76fd;
      border-radius:80px;
      margin:0 auto 60px;
      font-size:20px;
      line-height:80px;
      box-shadow:0 6px 15px 0 rgba(0, 0, 0, 0.15);
    }
    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          width:210px;
          margin-right:40px;
          &:nth-child(even) {
            padding-top:120px;
          }
        }
        .card {
          padding:40px 0;
          img {
            width:150px;
            height:150px;
          }
          .card-name {font-size:22px;}
        }
      }
    }
  }

  .section-counsel {
    max-width:1020px;
    margin:0 auto;
    padding:100px 0;

    .counsel-intro-wrap {
      .counsel-intro-title {
        margin-bottom:100px;
        strong {
          margin-bottom:20px;font-size:40px;
        }
        .counsel-intro-description {
          font-size:24px;
        }
      }
    }

    .counsel-textchat-wrap {
      .counsel-textchat-description {
        @extend .xl-text-start;
        @extend .xl-zone-7;
        margin-top:-60px;
        align-items:flex-start;
        font-size:18px;
        img {
          width:90px;
          height:90px;
          margin-bottom:20px;
        }
        strong {
          margin-bottom:20px;
          font-size:30px;
        }
      }

      .counsel-textchat-img {
        @extend .xl-text-start;
        @extend .xl-zone-5;
        img {
          max-width:420px;
        }
      }
    }

    .counsel-voicechat-wrap {
      .counsel-voicechat-description {
        @extend .xl-text-start;
        @extend .xl-zone-5;
        @extend .xl-order-1;
        margin-top:-60px;
        font-size:18px;
        img {
          width:90px;
          height:90px;
          margin-bottom:20px;
        }
        strong {
          margin-bottom:20px;
          font-size:30px;
        }
      }
      .counsel-voicechat-img {
        @extend .xl-text-start;
        @extend .xl-zone-7;
        @extend .xl-order-0;
        img {
          max-width:420px;
        }
      }
    }
  }

  .section-client {
    max-width:1020px;
    margin:0 auto;
    padding:100px 0;
    .client-intro-title {
      font-size:40px;
      margin-bottom:60px;
    }
    .client-card-group {
      @extend .xl-justify-content-between;
    }
    .client-card-wrap {
      @extend .xl-zone-6;
      margin-bottom:40px;
    }
    .client-card {
      height:490px;
      :lang(en) & {
        height:550px;
      }
    }
    .card-top-left {
      @extend .xl-me-auto;
    }
    .card-bottom-left {
      @extend .xl-me-auto;
    }
    .card-top-right {
      @extend .xl-ms-auto;
    }
    .card-bottom-right {
      @extend .xl-ms-auto;
    }

    .client-icon-img {
      margin-bottom:20px;

      img:nth-of-type(1) {
        width:40px;
        height:40px;
      }

      img:nth-of-type(2) {
        max-width:80px;
        height:80px;
      }
    }

    .client-card-title {
      font-size:24px;

      h2:nth-of-type(1) {
        margin-bottom:6px;
      }
    }

    .client-card-description {
      font-size:18px;
      margin-bottom:20px;
    }

    .close-quotes {
      margin-bottom:15px;

      img {
        width:40px;
        height:40px;
      }
    }

    .client-info {
      font-size:16px;
    }
  }

  .section-download {
    max-width:1020px;
    margin:0 auto;
    padding:100px 0;

    .download-title-wrap {
      @extend .xl-justify-content-center;
      font-size:40px;

      img {
        max-width:100px;
        width:100px;
        height:100px;
      }
    }

    .download-btn-wrap {
      ul {
        width:590px;
        li {
          padding:0 15px;
          a {
            width:280px;
            height:80px;
            border-radius:80px;
            padding:0 60px;
          }
        }
      }
    }
  }

  .section-thirdparty {
    max-width:1020px;
    margin:0 auto;
    padding:100px 0;
    .thirdparty-title-wrap {
      margin-bottom:60px;
      .thirdparty-title {
        margin-bottom:20px;
        font-size:40px;
      }
      .thirdparty-description {
        font-size:24px;
        span {
          font-size:18px;
        }
      }
    }
    .cont ul li .ele a {
      font-size:24px;
    }
  }

  .section-error {
    .num {
      padding-bottom:30px;
      font-size:150px;
    }

    .tit {
      padding-bottom:30px;
      font-size:40px;
    }

    .cont {
      padding-bottom:60px;
      font-size:24px;
    }

    .move-main {
      a {
        width:360px;
        height:80px;
        border-radius:100px;
        margin-right:15px;
        font-size:20px;
      }
    }

    .move-prev {
      a {
        width:360px;
        height:80px;
        border-radius:100px;
        margin-left:15px;
        font-size:20px;
      }
    }
  }
}