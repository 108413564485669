.row {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--custom-gutter-y));
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  margin-top: var(--custom-gutter-y);
}

.zone {
  flex: 1 0 0%;
}

.zone-auto {
  flex: 0 0 auto;
  width: auto !important;
}

.zone-1 {
  flex: 0 0 auto;
  width: 8.33333333% !important;
}

.zone-2 {
  flex: 0 0 auto;
  width: 16.66666667% !important;
}

.zone-3 {
  flex: 0 0 auto;
  width: 25% !important;
}

.zone-4 {
  flex: 0 0 auto;
  width: 33.33333333% !important;
}

.zone-5 {
  flex: 0 0 auto;
  width: 41.66666667% !important;
}

.zone-6 {
  flex: 0 0 auto;
  width: 50% !important;
}

.zone-7 {
  flex: 0 0 auto;
  width: 58.33333333% !important;
}

.zone-8 {
  flex: 0 0 auto;
  width: 66.66666667% !important;
}

.zone-9 {
  flex: 0 0 auto;
  width: 75% !important;
}

.zone-10 {
  flex: 0 0 auto;
  width: 83.33333333% !important;
}

.zone-11 {
  flex: 0 0 auto;
  width: 91.66666667% !important;
}

.zone-12 {
  flex: 0 0 auto;
  width: 100% !important;
}

@media (min-width: 768px) {
  .md-zone {
    flex: 1 0 0%;
  }
  .md-zone-auto {
    flex: 0 0 auto;
    width: auto !important;
  }
  .md-zone-1 {
    flex: 0 0 auto;
    width: 8.33333333% !important;
  }
  .md-zone-2 {
    flex: 0 0 auto;
    width: 16.66666667% !important;
  }
  .md-zone-3 {
    flex: 0 0 auto;
    width: 25% !important;
  }
  .md-zone-4 {
    flex: 0 0 auto;
    width: 33.33333333% !important;
  }
  .md-zone-5 {
    flex: 0 0 auto;
    width: 41.66666667% !important;
  }
  .md-zone-6 {
    flex: 0 0 auto;
    width: 50% !important;
  }
  .md-zone-7 {
    flex: 0 0 auto;
    width: 58.33333333% !important;
  }
  .md-zone-8 {
    flex: 0 0 auto;
    width: 66.66666667% !important;
  }
  .md-zone-9 {
    flex: 0 0 auto;
    width: 75% !important;
  }
  .md-zone-10 {
    flex: 0 0 auto;
    width: 83.33333333% !important;
  }
  .md-zone-11 {
    flex: 0 0 auto;
    width: 91.66666667% !important;
  }
  .md-zone-12 {
    flex: 0 0 auto;
    width: 100% !important;
  }
  .md-offset-0 {
    margin-left: 0;
  }
  .md-offset-1 {
    margin-left: 8.33333333%;
  }
  .md-offset-2 {
    margin-left: 16.66666667%;
  }
  .md-offset-3 {
    margin-left: 25%;
  }
  .md-offset-4 {
    margin-left: 33.33333333%;
  }
  .md-offset-5 {
    margin-left: 41.66666667%;
  }
  .md-offset-6 {
    margin-left: 50%;
  }
  .md-offset-7 {
    margin-left: 58.33333333%;
  }
  .md-offset-8 {
    margin-left: 66.66666667%;
  }
  .md-offset-9 {
    margin-left: 75%;
  }
  .md-offset-10 {
    margin-left: 83.33333333%;
  }
  .md-offset-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 1200px) {
  .xl-zone {
    flex: 1 0 0%;
  }
  .xl-zone-auto {
    flex: 0 0 auto;
    width: auto !important;
  }
  .xl-zone-1 {
    flex: 0 0 auto;
    width: 8.33333333% !important;
  }
  .xl-zone-2 {
    flex: 0 0 auto;
    width: 16.66666667% !important;
  }
  .xl-zone-3 {
    flex: 0 0 auto;
    width: 25% !important;
  }
  .xl-zone-4 {
    flex: 0 0 auto;
    width: 33.33333333% !important;
  }
  .xl-zone-5 {
    flex: 0 0 auto;
    width: 41.66666667% !important;
  }
  .xl-zone-6 {
    flex: 0 0 auto;
    width: 50% !important;
  }
  .xl-zone-7 {
    flex: 0 0 auto;
    width: 58.33333333% !important;
  }
  .xl-zone-8 {
    flex: 0 0 auto;
    width: 66.66666667% !important;
  }
  .xl-zone-9 {
    flex: 0 0 auto;
    width: 75% !important;
  }
  .xl-zone-10 {
    flex: 0 0 auto;
    width: 83.33333333% !important;
  }
  .xl-zone-11 {
    flex: 0 0 auto;
    width: 91.66666667% !important;
  }
  .xl-zone-12 {
    flex: 0 0 auto;
    width: 100% !important;
  }
  .xl-offset-0 {
    margin-left: 0;
  }
  .xl-offset-1 {
    margin-left: 8.33333333%;
  }
  .xl-offset-2 {
    margin-left: 16.66666667%;
  }
  .xl-offset-3 {
    margin-left: 25%;
  }
  .xl-offset-4 {
    margin-left: 33.33333333%;
  }
  .xl-offset-5 {
    margin-left: 41.66666667%;
  }
  .xl-offset-6 {
    margin-left: 50%;
  }
  .xl-offset-7 {
    margin-left: 58.33333333%;
  }
  .xl-offset-8 {
    margin-left: 66.66666667%;
  }
  .xl-offset-9 {
    margin-left: 75%;
  }
  .xl-offset-10 {
    margin-left: 83.33333333%;
  }
  .xl-offset-11 {
    margin-left: 91.66666667%;
  }
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.ps-0 {
  padding-left: 0 !important;
}

@media (min-width: 768px) {
  .md-d-inline {
    display: inline !important;
  }
  .md-d-inline-block {
    display: inline-block !important;
  }
  .md-d-block {
    display: block !important;
  }
  .md-d-grid {
    display: grid !important;
  }
  .md-d-table {
    display: table !important;
  }
  .md-d-table-row {
    display: table-row !important;
  }
  .md-d-table-cell {
    display: table-cell !important;
  }
  .md-d-flex {
    display: flex !important;
  }
  .md-d-inline-flex {
    display: inline-flex !important;
  }
  .md-d-none {
    display: none !important;
  }
  .md-flex-fill {
    flex: 1 1 auto !important;
  }
  .md-flex-row {
    flex-direction: row !important;
  }
  .md-flex-column {
    flex-direction: column !important;
  }
  .md-flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .md-flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  .md-flex-grow-0 {
    flex-grow: 0 !important;
  }
  .md-flex-grow-1 {
    flex-grow: 1 !important;
  }
  .md-flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .md-flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  .md-flex-wrap {
    flex-wrap: wrap !important;
  }
  .md-flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .md-flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .md-justify-content-start {
    justify-content: flex-start !important;
  }
  .md-justify-content-end {
    justify-content: flex-end !important;
  }
  .md-justify-content-center {
    justify-content: center !important;
  }
  .md-justify-content-between {
    justify-content: space-between !important;
  }
  .md-justify-content-around {
    justify-content: space-around !important;
  }
  .md-justify-content-evenly {
    justify-content: space-evenly !important;
  }
  .md-align-items-start {
    align-items: flex-start !important;
  }
  .md-align-items-end {
    align-items: flex-end !important;
  }
  .md-align-items-center {
    align-items: center !important;
  }
  .md-align-items-baseline {
    align-items: baseline !important;
  }
  .md-align-items-stretch {
    align-items: stretch !important;
  }
  .md-align-content-start {
    align-content: flex-start !important;
  }
  .md-align-content-end {
    align-content: flex-end !important;
  }
  .md-align-content-center {
    align-content: center !important;
  }
  .md-align-content-between {
    align-content: space-between !important;
  }
  .md-align-content-around {
    align-content: space-around !important;
  }
  .md-align-content-stretch {
    align-content: stretch !important;
  }
  .md-align-self-auto {
    align-self: auto !important;
  }
  .md-align-self-start {
    align-self: flex-start !important;
  }
  .md-align-self-end {
    align-self: flex-end !important;
  }
  .md-align-self-center {
    align-self: center !important;
  }
  .md-align-self-baseline {
    align-self: baseline !important;
  }
  .md-align-self-stretch {
    align-self: stretch !important;
  }
  .md-order-first {
    order: -1 !important;
  }
  .md-order-0 {
    order: 0 !important;
  }
  .md-order-1 {
    order: 1 !important;
  }
  .md-order-2 {
    order: 2 !important;
  }
  .md-order-3 {
    order: 3 !important;
  }
  .md-order-4 {
    order: 4 !important;
  }
  .md-order-5 {
    order: 5 !important;
  }
  .md-order-last {
    order: 6 !important;
  }
  .md-m-0 {
    margin: 0 !important;
  }
  .md-m-auto {
    margin: auto !important;
  }
  .md-mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .md-mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .md-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .md-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .md-mt-0 {
    margin-top: 0 !important;
  }
  .md-mt-auto {
    margin-top: auto !important;
  }
  .md-me-0 {
    margin-right: 0 !important;
  }
  .md-me-auto {
    margin-right: auto !important;
  }
  .md-mb-0 {
    margin-bottom: 0 !important;
  }
  .md-mb-auto {
    margin-bottom: auto !important;
  }
  .md-ms-0 {
    margin-left: 0 !important;
  }
  .md-ms-auto {
    margin-left: auto !important;
  }
  .md-p-0 {
    padding: 0 !important;
  }
  .md-px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .md-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .md-pt-0 {
    padding-top: 0 !important;
  }
  .md-pe-0 {
    padding-right: 0 !important;
  }
  .md-pb-0 {
    padding-bottom: 0 !important;
  }
  .md-ps-0 {
    padding-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .xl-d-inline {
    display: inline !important;
  }
  .xl-d-inline-block {
    display: inline-block !important;
  }
  .xl-d-block {
    display: block !important;
  }
  .xl-d-grid {
    display: grid !important;
  }
  .xl-d-table {
    display: table !important;
  }
  .xl-d-table-row {
    display: table-row !important;
  }
  .xl-d-table-cell {
    display: table-cell !important;
  }
  .xl-d-flex {
    display: flex !important;
  }
  .xl-d-inline-flex {
    display: inline-flex !important;
  }
  .xl-d-none {
    display: none !important;
  }
  .xl-flex-fill {
    flex: 1 1 auto !important;
  }
  .xl-flex-row {
    flex-direction: row !important;
  }
  .xl-flex-column {
    flex-direction: column !important;
  }
  .xl-flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .xl-flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  .xl-flex-grow-0 {
    flex-grow: 0 !important;
  }
  .xl-flex-grow-1 {
    flex-grow: 1 !important;
  }
  .xl-flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .xl-flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  .xl-flex-wrap {
    flex-wrap: wrap !important;
  }
  .xl-flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .xl-flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .xl-justify-content-start {
    justify-content: flex-start !important;
  }
  .xl-justify-content-end {
    justify-content: flex-end !important;
  }
  .xl-justify-content-center {
    justify-content: center !important;
  }
  .xl-justify-content-between {
    justify-content: space-between !important;
  }
  .xl-justify-content-around {
    justify-content: space-around !important;
  }
  .xl-justify-content-evenly {
    justify-content: space-evenly !important;
  }
  .xl-align-items-start {
    align-items: flex-start !important;
  }
  .xl-align-items-end {
    align-items: flex-end !important;
  }
  .xl-align-items-center {
    align-items: center !important;
  }
  .xl-align-items-baseline {
    align-items: baseline !important;
  }
  .xl-align-items-stretch {
    align-items: stretch !important;
  }
  .xl-align-content-start {
    align-content: flex-start !important;
  }
  .xl-align-content-end {
    align-content: flex-end !important;
  }
  .xl-align-content-center {
    align-content: center !important;
  }
  .xl-align-content-between {
    align-content: space-between !important;
  }
  .xl-align-content-around {
    align-content: space-around !important;
  }
  .xl-align-content-stretch {
    align-content: stretch !important;
  }
  .xl-align-self-auto {
    align-self: auto !important;
  }
  .xl-align-self-start {
    align-self: flex-start !important;
  }
  .xl-align-self-end {
    align-self: flex-end !important;
  }
  .xl-align-self-center {
    align-self: center !important;
  }
  .xl-align-self-baseline {
    align-self: baseline !important;
  }
  .xl-align-self-stretch {
    align-self: stretch !important;
  }
  .xl-order-first {
    order: -1 !important;
  }
  .xl-order-0 {
    order: 0 !important;
  }
  .xl-order-1 {
    order: 1 !important;
  }
  .xl-order-2 {
    order: 2 !important;
  }
  .xl-order-3 {
    order: 3 !important;
  }
  .xl-order-4 {
    order: 4 !important;
  }
  .xl-order-5 {
    order: 5 !important;
  }
  .xl-order-last {
    order: 6 !important;
  }
  .xl-m-0 {
    margin: 0 !important;
  }
  .xl-m-auto {
    margin: auto !important;
  }
  .xl-mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .xl-mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .xl-my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .xl-my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .xl-mt-0 {
    margin-top: 0 !important;
  }
  .xl-mt-auto {
    margin-top: auto !important;
  }
  .xl-me-0 {
    margin-right: 0 !important;
  }
  .xl-me-auto {
    margin-right: auto !important;
  }
  .xl-mb-0 {
    margin-bottom: 0 !important;
  }
  .xl-mb-auto {
    margin-bottom: auto !important;
  }
  .xl-ms-0 {
    margin-left: 0 !important;
  }
  .xl-ms-auto {
    margin-left: auto !important;
  }
  .xl-p-0 {
    padding: 0 !important;
  }
  .xl-px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .xl-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .xl-pt-0 {
    padding-top: 0 !important;
  }
  .xl-pe-0 {
    padding-right: 0 !important;
  }
  .xl-pb-0 {
    padding-bottom: 0 !important;
  }
  .xl-ps-0 {
    padding-left: 0 !important;
  }
}