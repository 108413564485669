// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// font
$FONT_FAMILY_KO: 'Noto Sans KR', sans-serif;
$FONT_FAMILY_EN: 'Noto Sans', sans-serif;
$FONT_FAMILY_VI: 'Noto Sans', sans-serif;

// PATH
$IMG_PATH: '/locale/global/img';
$IMG_PATH_KO: '/locale/ko/img';
$IMG_PATH_EN: '/locale/en/img';

// Color
$BACKGROUND_COLOR: #fff !default;
$FOREGROUND_COLOR: #202020 !default;

$TEXT_COLOR: $FOREGROUND_COLOR !default;
$TEXT_COLOR_REVERSE: $BACKGROUND_COLOR !default;