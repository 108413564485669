.g-center {
  display: flex;
  justify-content: center;
  align-items: center
}

//position
.p {
  &-relative {
    position: relative;
  }

  &-absolute {
    position: absolute;
  }

  &-fixed {
    position: fixed;
  }
}

//float
.float {
  &-start {
    float: left;
  }

  &-end {
    float: right;
  }
}

//about display
.d {
  &-flex-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center
  }

  &-contents {
    display: contents;
  }

  &-flex-vertical-center {
    display: flex;
    flex-direction: column;
    align-items: center
  }

  &-flex-column-y-center {
    display: flex;
    flex-direction: column;
    justify-content: center
  }
}

//about text
.text {
  &-center {
    text-align: center;
  }

  &-start {
    text-align: left;
  }

  &-end {
    text-align: right;
  }

  &-7f76fd {
    color: #7f76fd
  }

  &-b4b4b4 {
    color: #b4b4b4
  }

  &-f26b91 {
    color: #f26b91
  }

  &-e2ad00 {
    color: #e2ad00
  }

  &-836feb {
    color: #836feb
  }

  &-308cf3 {
    color: #308cf3
  }

  &-505050 {
    color: #505050
  }

  &-000000 {
    color: #000000;
  }

  &-white {
    color: var(--custom-white);
  }
}

//about background
.bg {
  &-white {
    background-color: var(--custom-white);
  }

  &-ffffff {
    background-color: var(--custom-white);
  }

  &-f8f8f8 {
    background-color: #f8f8f8
  }

  &-f8f6ff {
    background-color: #f8f6ff
  }

  &-F3F0FE {
    background-color: #f3f0fe
  }

  &-eeeeee {
    background-color: #eeeeee;
  }

  &-eaeaea {
    background-color: #eaeaea
  }

  &-7f76fdcc {
    background-color: rgba(127, 118, 253, .8)
  }

  &-7f76fd {
    background-color: #7f76fd
  }

  &-f16661cc {
    background-color: rgba(241, 102, 97, .8)
  }

  &-FC6658 {
    background-color: #fc6658
  }

  &-9966CC {
    background-color: #96c
  }

  &-44C3F8 {
    background-color: #44c3f8
  }
}

//gap
.g {
  &-5px {
    gap: 1.2500vw
  }

  &-10px {
    gap: 2.5000vw
  }
}

//gap x
.gx {
  &-5px {
    gap: 0 1.2500vw
  }

  &-10px {
    gap: 0 2.5000vw
  }
}

//gap y
.gy {
  &-5px {
    gap: 1.2500vw 0;
  }

  &-10px {
    gap: 2.5vw 0;
  }
}

//padding
.p {
  &-10px {
    padding: 2.5vw;
  }

  &-20px {
    padding: 5vw;
  }

  &-50px {
    padding: 12.5vw;
  }
}

//padding x
.px {
  &-5vw {
    padding-left: 5vw;
    padding-right: 5vw
  }

  &-7vw {
    padding-left: 7vw;
    padding-right: 7vw
  }

  &-5px {
    padding-left: 1.2500vw;
    padding-right: 1.2500vw;
  }

  &-10px {
    padding-left: 2.5vw;
    padding-right: 2.5vw
  }

  &-20px {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  &-30px {
    padding-left: 7.5vw;
    padding-right: 7.5vw
  }

  &-45px {
    padding-left: 11.25vw;
    padding-right: 11.25vw
  }

  &-50px {
    padding-left: 12.5vw;
    padding-right: 12.5vw
  }
}

//padding y
.py {
  &-5px {
    padding-top: 1.25vw;
    padding-bottom: 1.25vw
  }

  &-15px {
    padding-top: 3.75vw;
    padding-bottom: 3.75vw
  }

  &-20px {
    padding-top: 5vw;
    padding-bottom: 5vw
  }

  &-40px {
    padding-top: 10vw;
    padding-bottom: 10vw
  }

  &-20px-ori {
    padding-top: 20px;
    padding-bottom: 20px
  }

  &-24px-ori {
    padding-top: 24px;
    padding-bottom: 24px
  }

  &-50px {
    padding-top: 12.5vw;
    padding-bottom: 12.5vw
  }

  &-150px {
    padding-top: 37.5vw;
    padding-bottom: 37.5vw
  }
}

//padding left
.ps {
  &-3vw {
    padding-left: 3vw
  }

  &-4vw {
    padding-left: 4vw
  }

  &-6vw {
    padding-left: 6vw
  }

  &-10vw {
    padding-left: 10vw
  }

  &-11vw {
    padding-left: 11vw
  }

  &-13vw {
    padding-left: 13vw
  }

  &-20px {
    padding-left: 5vw
  }

  &-30px {
    padding-left: 7.5vw
  }

  &-450px {
    padding-left: 23.4375vw
  }
}

//padding right
.pe {
  &-3vw {
    padding-right: 3vw
  }

  &-4vw {
    padding-right: 4vw
  }

  &-5px {
    padding-right: 1.2500vw;
  }

  &-9px {
    padding-right: 0.75vw;
  }

  &-20px {
    padding-right: 5vw
  }

  &-30px {
    padding-right: 7.5vw
  }

  &-230px {
    padding-right: 57.5vw;
  }
}

//padding top
.pt {
  &-3vw {
    padding-top: 3vw
  }

  &-4vw {
    padding-top: 4vw
  }

  &-5vw {
    padding-top: 5vw
  }

  &-7vw {
    padding-top: 7vw
  }

  &-8vw {
    padding-top: 8vw
  }

  &-10px {
    padding-top: 2.5vw
  }

  &-20px {
    padding-top: 5vw
  }

  &-30px {
    padding-top: 7.5vw
  }

  &-50px {
    padding-top: 12.5vw
  }
}

//padding bottom
.pb {
  &-1_5vw {
    padding-bottom: 1.5vw
  }

  &-2vw {
    padding-bottom: 2vw
  }

  &-12vw {
    padding-bottom: 12vw
  }

  &-5px {
    padding-bottom: 1.25vw
  }

  &-10px {
    padding-bottom: 2.5vw
  }

  &-15px {
    padding-bottom: 3.75vw
  }

  &-20px {
    padding-bottom: 5vw
  }

  &-30px {
    padding-bottom: 7.5vw
  }

  &-50px {
    padding-bottom: 12.5vw;
  }
}

//margin

//margin x

//margin y
.my {
  &-50px {
    margin-top: 12.5vw;
    margin-bottom: 12.5vw
  }
}

//margin left
.ms {
  &-3px {
    margin-left: 0.2500vw
  }

  &-5px {
    margin-left: 1.25vw
  }

  &-10px {
    margin-left: 2.5vw
  }
}

//margin right
.me {
  &-3px {
    margin-right: 0.2500vw
  }

  &-5px {
    margin-right: 1.25vw
  }

  &-10px {
    margin-right: 2.5vw
  }
}

//margin top
.mt {
  &--13vw {
    margin-top: -13vw
  }

  &--30vw {
    margin-top: -30vw
  }

  &-117vw {
    margin-top: 117vw
  }

  &-10px {
    margin-top: 2.5vw
  }

  &-50px {
    margin-top: 12.5vw
  }

  &-60px {
    margin-top: 15vw
  }
}

//line-height
.lh {
  &-20px {
    line-height: 5vw
  }

  &-24px {
    line-height: 6vw
  }

  &-30px {
    line-height: 7.5vw
  }

  &-34px {
    line-height: 8.5vw
  }
}

//area
.area {
  &-18px {
    width: 4.5vw;
    height: 4.5vw
  }

  &-24px {
    width: 6vw;
    height: 6vw
  }

  &-48px {
    width: 12vw;
    height: 12vw
  }

  &-60px {
    width: 10vw;
    height: 10vw
  }

  &-70px {
    width: 17.5vw;
    height: 17.5vw;
  }

  &-80px {
    width: 20vw;
    height: 20vw
  }

  &-140px {
    width: 35vw;
    height: 35vw
  }

  &-160px {
    width: 40vw;
    height: 40vw
  }

  &-270px {
    width: 67.5vw;
    height: 67.5vw
  }
}

//about border
.border {
  &-50per {
    border-radius: 50%
  }

  &--10px {
    border-radius: 2.5vw
  }

  &--20px {
    border-radius: 5vw
  }

  &--60px {
    border-radius: 15vw
  }

  &--100px {
    border-radius: 25vw
  }
}

//화면넓이 768px 부터 적용되는 스타일
@media (min-width: 768px) {
  .btn-app:hover {
    animation-name: scalebig;
    animation-duration: .5s
  }

  .btn-app:not(:hover) {
    animation-name: scalesmall;
    animation-duration: .5s
  }

  .swiper-horizontal > .swiper-pagination-progressbar, .swiper-pagination-progressbar.swiper-pagination-horizontal {
    width: 14vw;
    height: 5px;
    left: 42vw;
    border-radius: 1.5625vw;
  }

  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background-color: #000;
  }

  .md-ani.ani-test {
    animation-duration: 3s;
    animation-name: test
  }

  .md-ani.ani-focus-in {
    animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both
  }

  .md-ani.ani-focus-in-back {
    animation: focus-in-contract-bck 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
  }

  .md-ani.ani-color-change-3x {
    animation: color-change-3x 4s linear infinite alternate both
  }

  .md-pill-red {
    border-radius: 22px;
    background-color: #f16661;
    padding: 6px 11px;
    color: #fff;
    width: fit-content
  }

  .md-pill-blue {
    border-radius: 22px;
    background-color: #7f76fd;
    padding: 6px 11px;
    color: #fff;
    width: fit-content
  }

  .md-pill-dark {
    border-radius: 22px;
    background-color: #505050;
    padding: 6px 11px;
    color: #fff;
    width: fit-content
  }

  .md-pill-yellow {
    border-radius: 22px;
    background-color: #ffb453;
    padding: 6px 11px;
    color: #fff;
    width: fit-content
  }

  .md-d-contents {
    display: contents !important;
  }

  .md-d-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center
  }

  .md-blue-btn {
    padding: .3646vw .625vw .2083vw .625vw;
    border-radius: 3.125vw;
    background-color: #0067b8;
    color: #fff
  }

  .md-shadow-pro {
    filter: drop-shadow(0 0.3125vw 0.7813vw rgba(0, 0, 0, 0.2))
  }

  //(md)width
  .md-w {
    &-100 {
      width: 100% !important;
    }

    &-100px {
      width: 5.2083vw
    }

    &-133px {
      width: 6.9271vw
    }

    &-140px {
      width: 7.2917vw
    }

    &-150px {
      width: 7.8125vw
    }

    &-159px {
      width: 8.2813vw
    }

    &-180px {
      width: 9.375vw
    }

    &-210px {
      width: 10.9375vw
    }

    &-250px {
      width: 13.0208vw
    }

    &-280px {
      width: 14.5833vw
    }

    &-335px {
      width: 17.4479vw
    }

    &-540px {
      width: 28.125vw
    }

    &-825px {
      width: 42.9688vw !important;
    }
  }

  //(md)height
  .md-h {
    &-0 {
      height: 0;
    }

    &-40px {
      height: 2.0833vw;
    }

    &-80px {
      height: 4.1667vw
    }

    &-120px {
      height: 6.25vw
    }

    &-200px {
      height: 10.4167vw
    }

    &-220px {
      height: 11.4583vw
    }

    &-240px {
      height: 12.5vw
    }

    &-300px {
      height: 15.625vw
    }

    &-360px {
      height: 18.75vw
    }

    &-400px {
      height: 20.8333vw;
    }

    &-460px {
      height: 23.9583vw
    }

    &-480px {
      height: 25vw
    }

    &-500px {
      height: 26.0417vw
    }

    &-650px {
      height: 33.8542vw;
    }
  }

  //(md)font-size
  .md-fs {

    &-13px {
      font-size: .6771vw
    }

    &-15px {
      font-size: .7813vw
    }

    &-16px {
      font-size: .8333vw
    }

    &-18px {
      font-size: .9375vw
    }

    &-20px {
      font-size: 1.0417vw
    }

    &-24px {
      font-size: 1.25vw
    }

    &-30px {
      font-size: 1.5625vw
    }

    &-40px {
      font-size: 2.0833vw
    }

    &-50px {
      font-size: 2.6042vw
    }

    &-74px {
      font-size: 3.8542vw
    }
  }

  //(md)position
  .md-p-fixed {
    position: fixed;
  }

  //(md)float
  .md-float {
    &-start {
      float: left;
    }

    &-end {
      float: right;
    }
  }

  //(md)about text
  .md-text {
    &-end {
      text-align: right;
    }

    &-start {
      text-align: left;
    }
  }

  //(md)gap
  .md-g {
    &-5px {
      gap: 0.2604vw;
    }

    &-10px {
      gap: 0.5208vw;
    }
  }

  //(md)gap x
  .md-gx {
    &-5px {
      gap: 0 0.2604vw;
    }

    &-10px {
      gap: 0 0.5208vw;
    }
  }

  //(md)gap y
  .md-gy {
    &-5px {
      gap: 0.2604vw 0;
    }

    &-10px {
      gap: 0.5208vw 0;
    }
  }

  //(md)padding
  .md-p {
    &-15px {
      padding: .7813vw
    }

    &-30px {
      padding: 1.5625vw
    }

    &-45px {
      padding: 2.3438vw
    }
  }

  //(md)padding x
  .md-px {
    &-7vw {
      padding-left: 7vw;
      padding-right: 7vw
    }

    &-5px {
      padding-left: .2804vw;
      padding-right: .2804vw;
    }

    &-20px {
      padding-left: 1.0417vw;
      padding-right: 1.0417vw
    }

    &-40px {
      padding-left: 2.0833vw;
      padding-right: 2.0833vw
    }

    &-400px {
      padding-left: 20.8333vw;
      padding-right: 20.8333vw
    }

    &-420px {
      padding-left: 21.875vw;
      padding-right: 21.875vw
    }

    &-430px {
      padding-left: 22.3958vw;
      padding-right: 22.3958vw
    }

    &-450px {
      padding-left: 23.4375vw;
      padding-right: 23.4375vw
    }

    &-490px {
      padding-left: 25.5208vw;
      padding-right: 25.5208vw
    }

    &-615px {
      padding-left: 32.0313vw;
      padding-right: 32.0313vw
    }
  }

  //(md)padding y
  .md-py {

    &-14px {
      padding-top: .7292vw;
      padding-bottom: .7292vw
    }

    &-20px {
      padding-top: 1.0417vw;
      padding-bottom: 1.0417vw
    }

    &-30px {
      padding-top: 1.5625vw;
      padding-bottom: 1.5625vw
    }

    &-40px {
      padding-top: 2.0833vw;
      padding-bottom: 2.0833vw
    }

    &-60px {
      padding-top: 3.125vw;
      padding-bottom: 3.125vw
    }

    &-77px {
      padding-top: 3.75vw;
      padding-bottom: 3.75vw
    }

    &-80px {
      padding-top: 4.1667vw;
      padding-bottom: 4.1667vw
    }

    &-100px {
      padding-top: 5.2083vw;
      padding-bottom: 5.2083vw
    }
  }

  //(md)padding left
  .md-ps {
    &-1_2vw {
      padding-left: 1.2vw
    }

    &-5px {
      padding-left: .2804vw
    }

    &-10px {
      padding-left: .5208vw
    }

    &-20px {
      padding-left: 1.0417vw
    }

    &-30px {
      padding-left: 1.5625vw
    }

    &-40px {
      padding-left: 2.0833vw
    }

    &-55px {
      padding-left: 2.8646vw
    }

    &-450px {
      padding-left: 23.4375vw
    }
  }

  //(md)padding right
  .md-pe {

    &-1_2vw {
      padding-right: 1.2vw
    }

    &-5px {
      padding-right: .2804vw
    }

    &-20px {
      padding-right: 1.0417vw
    }

    &-30px {
      padding-right: 1.5625vw;
    }

    &-40px {
      padding-right: 2.0833vw;
    }
  }

  //(md)padding top
  .md-pt {
    &-14px {
      padding-top: .7292vw;
    }

    &-30px {
      padding-top: 1.5625vw
    }

    &-40px {
      padding-top: 2.0833vw
    }

    &-80px {
      padding-top: 4.1667vw
    }

    &-100px {
      padding-top: 5.2083vw
    }
  }

  //(md)padding bottom
  .md-pb {

    &-5px {
      padding-bottom: .2604vw
    }

    &-10px {
      padding-bottom: .5208vw
    }

    &-20px {
      padding-bottom: 1.0417vw
    }

    &-80px {
      padding-bottom: 4.1667vw
    }

    &-100px {
      padding-bottom: 5.2083vw
    }
  }

  //(md)margin y
  .md-my {
    &-100px {
      margin-top: 5.2083vw;
      margin-bottom: 5.2083vw
    }
  }

  //(md)margin left
  .md-ms {
    &-5px {
      margin-left: .2604vw
    }

    &-15px {
      margin-left: .7813vw
    }

    &-30px {
      margin-left: 1.5625vw
    }

    &--1vw {
      margin-left: -1vw
    }

    &--3vw {
      margin-left: -3vw
    }

    &-2vw {
      margin-left: 2vw
    }

    &-2_5vw {
      margin-left: 2.5vw
    }
  }

  //(md)margin right
  .md-me {
    &-15px {
      margin-right: .7813vw
    }

    &--3vw {
      margin-right: -3vw
    }
  }

  //(md)margin top
  .md-mt {
    &-39vw {
      margin-top: 39vw
    }

    &-10px {
      margin-top: 0.5208vw
    }

    &-15px {
      margin-top: 0.7813vw
    }

    &-40px {
      margin-top: 2.0833vw
    }

    &--40px {
      margin-top: -2.0833vw
    }

    &-60px {
      margin-top: 3.125vw
    }

    &--60px {
      margin-top: -3.125vw
    }

    &-80px {
      margin-top: 4.1667vw
    }

    &--80px {
      margin-top: -4.1667vw
    }

    &-100px {
      margin-top: 5.2083vw
    }

    &--100px {
      margin-top: -5.2083vw
    }

    &-120px {
      margin-top: 6.25vw
    }

    &-130px {
      margin-top: 6.7708vw
    }

    &-150px {
      margin-top: 7.8125vw
    }

    &-180px {
      margin-top: 9.375vw
    }

    &-230px {
      margin-top: 11.9792vw
    }
  }
}

//화면넓이 1200px 부터 적용되는 스타일
@media (min-width: 1200px) {
  .xl-p-fixed {
    position: fixed;
  }

  .xl-top-50per {
    top: 50%;
  }

  .xl-bottom-30px {
    bottom: 1.5625vw;
  }

  .xl-end-3per {
    right: 3%;
  }

  .xl-end-4per {
    right: 4%;
  }

  .xl-gy-5px {
    gap: 0.2604vw 0;
  }

  .xl-gy-10px {
    gap: 0.5208vw 0;
  }

  .xl-gx-5px {
    gap: 0 0.2604vw;
  }

  .xl-gx-10px {
    gap: 0 0.5208vw;
  }

  .xl-g-5px {
    gap: 0.2604vw;
  }

  .xl-g-10px {
    gap: 0.5208vw;
  }

  .xl-h-0 {
    height: 0;
  }

  .xl-w-100 {
    width: 100% !important;
  }

  .xl-float-start {
    float: left;
  }

  .xl-float-end {
    float: right;
  }

  .xl-text-end {
    text-align: right;
  }

  .xl-text-start {
    text-align: left;
  }

  .btn-app:hover {
    animation-name: scalebig;
    animation-duration: .5s
  }

  .btn-app:not(:hover) {
    animation-name: scalesmall;
    animation-duration: .5s
  }

  .swiper-horizontal > .swiper-pagination-progressbar, .swiper-pagination-progressbar.swiper-pagination-horizontal {
    width: 200px;
    height: 5px;
    left: 44.5vw;
    border-radius: 1.5625vw
  }

  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background-color: #000;
    border-radius: 100px;
  }

  .xl-ani.ani-test {
    animation-duration: 3s;
    animation-name: test
  }

  .xl-ani.ani-focus-in {
    animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both
  }

  .xl-ani.ani-focus-in-back {
    animation: focus-in-contract-bck 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
  }

  .xl-ani.ani-color-change-3x {
    animation: color-change-3x 4s linear infinite alternate both
  }

  .xl-pill-red {
    font-size: 18px;
    border-radius: 60px;
    background-color: #f16661;
    padding: 11px 20px;
    color: #fff;
    width: fit-content
  }

  .xl-pill-blue {
    font-size: 18px;
    border-radius: 60px;
    background-color: #7f76fd;
    padding: 11px 20px;
    color: #fff;
    width: fit-content
  }

  .xl-pill-dark {
    font-size: 18px;
    border-radius: 60px;
    background-color: #505050;
    padding: 11px 20px;
    color: #fff;
    width: fit-content
  }

  .xl-pill-yellow {
    font-size: 18px;
    border-radius: 60px;
    background-color: #ffb453;
    padding: 11px 28px;
    color: #fff;
    width: fit-content;
  }

  .xl-d-contents {
    display: contents !important;
  }

  .xl-d-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center
  }

  .xl-blue-btn {
    padding: .3646vw .625vw .2083vw .625vw;
    border-radius: 3.125vw;
    background-color: #0067b8;
    color: #fff
  }

  .xl-shadow-pro {
    filter: drop-shadow(0 0.3125vw 0.7813vw rgba(0, 0, 0, 0.2))
  }

  .xl-w-100px {
    width: 5.2083vw
  }

  .xl-w-133px {
    width: 6.9271vw
  }

  .xl-w-140px {
    width: 7.2917vw
  }

  .xl-w-150px {
    width: 7.8125vw
  }

  .xl-w-159px {
    width: 8.2813vw
  }

  .xl-w-180px {
    width: 9.375vw
  }

  .xl-w-210px {
    width: 10.9375vw
  }

  .xl-w-250px {
    width: 13.0208vw
  }

  .xl-w-280px {
    width: 14.5833vw
  }

  .xl-w-335px {
    width: 17.4479vw
  }

  .xl-w-540px {
    width: 28.125vw
  }

  .xl-w-825px {
    width: 42.9688vw !important;
  }

  .xl-h-40px {
    height: 2.0833vw;
  }

  .xl-h-80px {
    height: 4.1667vw
  }

  .xl-h-120px {
    height: 6.25vw
  }

  .xl-h-200px {
    height: 10.4167vw
  }

  .xl-h-220px {
    height: 11.4583vw
  }

  .xl-h-240px {
    height: 12.5vw
  }

  .xl-h-300px {
    height: 15.625vw
  }

  .xl-h-360px {
    height: 18.75vw
  }

  .xl-h-400px {
    height: 20.8333vw;
  }

  .xl-h-460px {
    height: 23.9583vw
  }

  .xl-h-480px {
    height: 25vw
  }

  .xl-h-500px {
    height: 26.0417vw
  }

  .xl-h-650px {
    height: 33.8542vw;
  }

  .xl-area-40px {
    width: 2.0833vw;
    height: 2.0833vw
  }

  .xl-area-60px {
    width: 3.125vw;
    height: 3.125vw
  }

  .xl-area-24px {
    width: 1.25vw;
    height: 1.25vw
  }

  .xl-area-80px {
    width: 4.1667vw;
    height: 4.1667vw
  }

  .xl-area-90px {
    width: 4.6875vw;
    height: 4.6875vw
  }

  .xl-area-100px {
    width: 5.2083vw;
    height: 5.2083vw
  }

  .xl-area-150px {
    width: 7.8125vw;
    height: 7.8125vw
  }

  .xl-area-210px {
    width: 10.9375vw;
    height: 10.9375vw
  }

  .xl-lh-24px {
    line-height: 1.25vw
  }

  .xl-lh-30px {
    line-height: 1.5625vw
  }

  .xl-lh-34px {
    line-height: 1.7708vw
  }

  .xl-lh-36px {
    line-height: 1.875vw
  }

  .xl-lh-54px {
    line-height: 2.8125vw
  }

  .xl-lh-74px {
    line-height: 3.8542vw
  }

  .xl-fs-13px {
    font-size: .6771vw
  }

  .xl-fs-15px {
    font-size: .7813vw
  }

  .xl-fs-16px {
    font-size: .8333vw
  }

  .xl-fs-18px {
    font-size: .9375vw
  }

  .xl-fs-20px {
    font-size: 1.0417vw
  }

  .xl-fs-24px {
    font-size: 1.25vw
  }

  .xl-fs-30px {
    font-size: 1.5625vw
  }

  .xl-fs-40px {
    font-size: 2.0833vw
  }

  .xl-fs-50px {
    font-size: 2.6042vw
  }

  .xl-fs-74px {
    font-size: 3.8542vw
  }

  .xl-my-100px {
    margin-top: 5.2083vw;
    margin-bottom: 5.2083vw
  }

  .xl-ms-5px {
    margin-left: .2604vw
  }

  .xl-ms-15px {
    margin-left: .7813vw
  }

  .xl-ms-30px {
    margin-left: 1.5625vw
  }

  .xl-ms--1vw {
    margin-left: -1vw
  }

  .xl-ms--3vw {
    margin-left: -3vw
  }

  .xl-ms-2vw {
    margin-left: 2vw
  }

  .xl-ms-2_5vw {
    margin-left: 2.5vw
  }

  .xl-me-15px {
    margin-right: .7813vw
  }

  .xl-me--3vw {
    margin-right: -3vw
  }

  .xl-mt-39vw {
    margin-top: 39vw
  }

  .xl-mt-10px {
    margin-top: 0.5208vw
  }

  .xl-mt-15px {
    margin-top: 0.7813vw
  }

  .xl-mt-40px {
    margin-top: 2.0833vw
  }

  .xl-mt--40px {
    margin-top: -2.0833vw
  }

  .xl-mt-60px {
    margin-top: 3.125vw
  }

  .xl-mt--60px {
    margin-top: -3.125vw
  }

  .xl-mt-80px {
    margin-top: 4.1667vw
  }

  .xl-mt--80px {
    margin-top: -4.1667vw
  }

  .xl-mt-100px {
    margin-top: 5.2083vw
  }

  .xl-mt--100px {
    margin-top: -5.2083vw
  }

  .xl-mt-120px {
    margin-top: 6.25vw
  }

  .xl-mt-130px {
    margin-top: 6.7708vw
  }

  .xl-mt-150px {
    margin-top: 7.8125vw
  }

  .xl-mt-180px {
    margin-top: 9.375vw
  }

  .xl-mt-230px {
    margin-top: 11.9792vw
  }

  .xl-mb-10px {
    margin-bottom: .5208vw
  }

  .xl-mb-20px {
    margin-bottom: 1.0417vw
  }

  .xl-mb-30px {
    margin-bottom: 1.5625vw
  }

  .xl-mb-40px {
    margin-bottom: 2.0833vw
  }

  .xl-mb-60px {
    margin-bottom: 3.125vw
  }

  .xl-mb-80px {
    margin-bottom: 4.1667vw
  }

  .xl-mb--80px {
    margin-bottom: -4.1667vw
  }

  .xl-mb-100px {
    margin-bottom: 5.2083vw
  }

  .xl-border-50per {
    border-radius: 50%
  }

  .xl-border-10px {
    border-radius: .5208vw
  }

  .xl-border-20px {
    border-radius: 1.0417vw
  }

  .xl-border-30px {
    border-radius: 1.5625vw
  }

  .xl-border-100px {
    border-radius: 5.2083vw
  }

  .xl-border-200px {
    border-radius: 10.4167vw
  }

  .xl-p-15px {
    padding: .7813vw
  }

  .xl-p-30px {
    padding: 1.5625vw
  }

  .xl-p-45px {
    padding: 2.3438vw
  }

  .xl-pt-14px {
    padding-top: .7292vw;
  }

  .xl-py-14px {
    padding-top: .7292vw;
    padding-bottom: .7292vw
  }

  .xl-py-20px {
    padding-top: 1.0417vw;
    padding-bottom: 1.0417vw
  }

  .xl-py-30px {
    padding-top: 1.5625vw;
    padding-bottom: 1.5625vw
  }

  .xl-py-40px {
    padding-top: 2.0833vw;
    padding-bottom: 2.0833vw
  }

  .xl-py-60px {
    padding-top: 3.125vw;
    padding-bottom: 3.125vw
  }

  .xl-py-77px {
    padding-top: 3.75vw;
    padding-bottom: 3.75vw
  }

  .xl-py-80px {
    padding-top: 4.1667vw;
    padding-bottom: 4.1667vw
  }

  .xl-py-100px {
    padding-top: 5.2083vw;
    padding-bottom: 5.2083vw
  }

  .xl-px-5px {
    padding-left: .2804vw;
    padding-right: .2804vw;
  }

  .xl-px-20px {
    padding-left: 1.0417vw;
    padding-right: 1.0417vw
  }

  .xl-px-40px {
    padding-left: 2.0833vw;
    padding-right: 2.0833vw
  }

  .xl-px-7vw {
    padding-left: 7vw;
    padding-right: 7vw
  }

  .xl-px-400px {
    padding-left: 20.8333vw;
    padding-right: 20.8333vw
  }

  .xl-px-420px {
    padding-left: 21.875vw;
    padding-right: 21.875vw
  }

  .xl-px-430px {
    padding-left: 22.3958vw;
    padding-right: 22.3958vw
  }

  .xl-px-450px {
    padding-left: 23.4375vw;
    padding-right: 23.4375vw
  }

  .xl-px-490px {
    padding-left: 25.5208vw;
    padding-right: 25.5208vw
  }

  .xl-px-615px {
    padding-left: 32.0313vw;
    padding-right: 32.0313vw
  }

  .xl-pt-30px {
    padding-top: 1.5625vw
  }

  .xl-pt-40px {
    padding-top: 2.0833vw
  }

  .xl-pt-80px {
    padding-top: 4.1667vw
  }

  .xl-pt-100px {
    padding-top: 5.2083vw
  }

  .xl-ps-5px {
    padding-left: .2804vw
  }

  .xl-ps-10px {
    padding-left: .5208vw
  }

  .xl-ps-20px {
    padding-left: 1.0417vw
  }

  .xl-ps-30px {
    padding-left: 1.5625vw
  }

  .xl-ps-40px {
    padding-left: 2.0833vw
  }

  .xl-ps-55px {
    padding-left: 2.8646vw
  }

  .xl-ps-450px {
    padding-left: 23.4375vw
  }

  .xl-ps-1_2vw {
    padding-left: 1.2vw
  }

  .xl-pe-1_2vw {
    padding-right: 1.2vw
  }

  .xl-pe-5px {
    padding-right: .2804vw
  }

  .xl-pe-20px {
    padding-right: 1.0417vw
  }

  .xl-pe-30px {
    padding-right: 1.5625vw;
  }

  .xl-pe-40px {
    padding-right: 2.0833vw;
  }

  .xl-pb-5px {
    padding-bottom: .2604vw
  }

  .xl-pb-10px {
    padding-bottom: .5208vw
  }

  .xl-pb-20px {
    padding-bottom: 1.0417vw
  }

  .xl-pb-80px {
    padding-bottom: 4.1667vw
  }

  .xl-pb-100px {
    padding-bottom: 5.2083vw
  }
}