// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/* reset */
* {margin:0;padding:0;box-sizing:border-box;text-size-adjust:none;}
html,body {height:100%;position:relative;}
ul,ol,li {margin:0;list-style:none;}
input,select,textarea {vertical-align:top;}
input:not([type="radio"]):not([type="checkbox"]),textarea {-webkit-appearance:none;}
select {-webkit-border-radius:0;}
button {background-color:transparent;border:0;margin:0;padding:0;font-family:inherit;color:inherit;overflow:visible;white-space:nowrap;cursor:pointer;}
img {max-width:100%;vertical-align:top;-ms-interpolation-mode:bicubic;}
table {width:100%;border-collapse:collapse;border-spacing:0;}
main,nav,header,section,article,aside,footer {display:block;}
address,em {font-style:normal;font-weight:normal;}
.btn:active, .btn:focus, .btn.active, .btn:active:focus {outline:0;}
.sr-only {width:1px;height:1px;border:0;margin:-1px;padding:0;overflow:hidden;position:absolute;clip:rect(0,0,0,0);}
h1,h2,h3 {font-size:initial;font-weight:initial;}