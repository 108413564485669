/**
 * Basic typography style for copy text
 */

// default
body {
  font-family:$FONT_FAMILY_KO;
  font-size:14px;
  color:$TEXT_COLOR;
  line-height:1.4;
  letter-spacing:-1px;
  word-break:keep-all;
  word-wrap:normal;
  :lang(en) & {
    font-family:$FONT_FAMILY_EN;
    letter-spacing:0;
  }
  :lang(vi) & {
    font-family:$FONT_FAMILY_VI;
    letter-spacing:0;
  }
}

// link
a:link,a:visited,a:hover,a:active {
  color:$TEXT_COLOR;
  text-decoration:none;
}